<template>

	<div class="page-wrapper">

		<Navbar/>

		<v-container>
			<v-row>
				<v-col class="menu-container" lg="4" md="6" cols="12">
					<v-btn to="/campagnes" tile plain class="menu-btn menu-campagnes">
						<p class="menu-item-text">Mes campagnes commerciales</p>

						<p class="d-none menu-item-details">Tous les outils de vos campagnes commerciales locales.</p>

						<div class="menu-item-img"></div>
					</v-btn>
				</v-col>

				<v-col class="menu-container" lg="4" md="6" cols="12">
					<v-btn to="/point-vente" tile plain class="menu-btn menu-point-vente">
						<p class="menu-item-text">Mon point de vente</p>

						<p class="d-none menu-item-details">Catalogues, signalétique, PLV, ILV, édition, <br> goodies pour votre communication instore.</p>

						<div class="menu-item-img"></div>
					</v-btn>
				</v-col>

				<v-col class="menu-container" lg="4" md="6" cols="12">
					<v-btn to="/activation" tile plain class="menu-btn menu-activation">
						<p class="menu-item-text">Mon activation</p>

						<p class="d-none menu-item-details">Créer, calibrer, cibler et diffuser vos messages, <br> mails et SMS sur votre zone de chalandise.</p>

						<div class="menu-item-img"></div>

					</v-btn>
				</v-col>

				<v-col class="menu-container" lg="4" md="6" cols="12">
					<v-btn to="/reseaux-sociaux" tile plain class="menu-btn menu-reseaux-sociaux">
						<p class="menu-item-text">Mes réseaux sociaux</p>

						<p class="d-none menu-item-details">Stratégie éditoriale, posts et animation <br> au mois le mois sur les réseaux.</p>

						<div class="menu-item-img"></div>

					</v-btn>
				</v-col>

				<v-col class="menu-container" lg="4" md="6" cols="12">
					<v-btn to="/corporate-beau-vivre" tile plain class="menu-btn menu-corporate-beau-vivre">
						<p class="menu-item-text">Corporate Le Beau Vivre</p>

						<p class="d-none menu-item-details">Tous vos outils et conseils <br> pour communiquer Le Beau Vivre</p>

						<div class="menu-item-img"></div>

					</v-btn>
				</v-col>

				<v-col class="menu-container" lg="2" md="3" cols="6">
					<v-btn to="/produits" tile plain class="menu-btn menu-produits">
						<p class="menu-item-text">Mes Produits</p>

						<p class="d-none menu-item-details">Toutes vos gammes : <br> cuisines, aménagements, <br> éléctro...</p>

						<div class="menu-item-img"></div>

					</v-btn>
				</v-col>

				<v-col class="menu-container" lg="2" md="3" cols="6">
					<v-btn to="/kit-ouverture" tile plain class="menu-btn menu-kit-ouverture font-weight-regular">
						<p class="menu-item-text">Mon kit d'ouverture</p>

						<p class="d-none menu-item-details">Tous les outils pour une <br> campagne de lancement <br> réussie de votre magasin</p>

						<div class="menu-item-img"></div>

					</v-btn>
				</v-col>
			</v-row>
		</v-container>

		<Footer/>
	</div>

</template>

<script>
import Footer from '@/components/Footer.vue'
import Navbar from '@/components/Navbar.vue'

export default {

	data() {
		return {
			drawer: false,
			group: null,
			email_modal: false,
			phone_modal: false,
			cgv_modal: false,
		}
	},

	components: {
		Footer,
		Navbar,
	},

	computed: {

    },

	methods: {

	}
}
</script>

<style lang="scss">
@import "~@/assets/styles/navigation.scss";
</style>