<template>
	<div>
		PageNotFound
	</div>
</template>

<script>
export default {

	data() {
		return {

		}
	},

	computed: {

    },

	methods: {

	}
}
</script>