<template>

	<div class="login-container">
		<div class="logo"></div>

		<v-row no-gutters class="black">
			<v-col lg="6" cols="12" class="login-img"></v-col>

			<v-col lg="6" cols="12" class="form-container d-flex justify-center align-center">

				<v-container class="text-center">
					<p class="font-weight-light ma-0 text-bienvenue">Bienvenue sur la plateforme</p>

          <img src="../assets/img/mynoblessa-blanc.svg" width="50%" class="mt-4">

					<h5 class="text-subtitle-1 font-weight-regular mb-12 mt-6" style="font-family: 'Helvetica Neue'">vous êtes déconnecté</h5>

					<v-row no-gutters>

						<v-col lg="4" md="2" sm="1" cols="12"></v-col>

						<v-col lg="4" md="8" sm="10" cols="12">
							<form @submit.prevent="submit">
								<v-text-field
									v-model="login"
									label="Login"
									:error-messages="validation_errors.login"
									required
								></v-text-field>

								<v-text-field
									v-model="password"
									type="password"
									:error-messages="validation_errors.password"
									label="Mot de passe"
									required
								></v-text-field>

								<v-btn medium outlined text tile :loading="loading" class="my-4 font-weight-light submit-btn" color="white" @click="submit">
									c'est parti !
								</v-btn>

								<v-btn plain style="font-family: 'Helvetica Neue'" class="my-4 font-weight-light reset-password-btn" to="/reset-pasword">login ou mot de passe publié</v-btn>

							</form>
						</v-col>

						<v-col lg="4" md="2" sm="1" cols="12"></v-col>

					</v-row>
				</v-container>

			</v-col>
		</v-row>
	</div>

</template>

<script>

export default {
  created() {
    localStorage.clear();
  },
  data() {
		return {
			validation_errors: {
				login: null,
				password: null,
			},

			login: '',
			password: '',
      loading: false,
		}
	},

	computed: {

    },

	methods: {
		submit() {
      this.loading = true
			let email = this.login
      let password = this.password

            this.$store.dispatch('login', { email, password })
            .then(() => {
              this.loading = false
				this.validation_errors = {
					login: null,
					password: null,
				}
				this.$router.push('/select-profil')
			})
            .catch(err => {
              this.loading = false
				console.log(err.response.data)

				if(err.response.data.errors) {
					this.validation_errors = err.response.data.errors
				}

				if(err.response.data.error) {
					this.$emit('flash-danger-snackbar', err.response.data.error)
				}
			})
		}
	}
}
</script>
<style>
.font-helvetica {
  font-family: "Helvetica Neue";
}
</style>