<template>
  <v-container>
    <div>
      <h3 class="black--text">CONDITIONS GENERALES DE VENTE</h3>
      <br>
      <h4>ENTRE :</h4>
      <br>
      <p>
        La société NOBLESSA, Société par actions simplifiée au capital de 200.000 €
        ayant pour numéro unique d’identification 824 039 408 RCS BOBIGNY et pour siège
        social, 17 Avenue de Strasbourg ZAC Parc des Collines 68350 BRUNSTATT -DIDENHEIM,
        représentée par MATTHIAS KEUDEL, en qualité de Président, ainsi que ses magasins
        affiliés à retrouver en annexe, ci-après dénommé le Client.
      </p>
      <br>
      <h4>ET : </h4>
      <br>
      <p>
        La Société CAB [TAXI], SASU au capital social de 1 000 euros, immatriculée au
        RCS de NANTERRE sous le N° 834 046 823, dont le siège social est sis à
        Boulogne-Billancourt (92100) au 37-41 rue Fernand Pelloutier, représentée par son
        Président, ci-après dénommée l’Agence.
      </p>
      <br>
      <h3 class="black--text">PREAMBULE : </h3>
      <br>
      <p>
        Tout contrat conclu entre le client et l’Agence et/ou tout devis émis par l’Agence et signé par le client impliquent nécessairement l’acceptation sans réserve des présentes conditions générales de vente ou de fourniture de services et renvoie expressément à ces dernières, qui lui sont annexées.
        Sous réserve des lois et règlements en vigueur, les présentes conditions générales prévalent sur toutes autres conditions contraires, sauf stipulations particulières expressément convenues entre l’Agence et le Client, étant précisé que ces dernières ne pourraient être invoquées à titre de précédent pour des opérations ultérieures.
        Si, pour une raison quelconque, une clause des présentes conditions générales devait être déclarée inapplicable, cette inapplicabilité ne remettrait pas en cause la validité des autres conditions ; la clause écartée étant alors remplacée par la stipulation la plus proche possible.
        Les conditions générales prévaudront, le cas échéant, sur toute autre version et sur les propres conditions d’achat ou autres du client.
      </p>
      <br>
      <h3 class="black--text">ARTICLE 1 : DISPOSITIONS GENERALES </h3>
      <br>
      <p>
        CAB a pour activités le conseil en communication, publicité et marketing opérationnel (le conseil stratégique, la création, le marketing services et opérationnel, la promotion des ventes, le marketing direct, la stimulation de réseaux et de forces de ventes, la création de sites internet, l’activation Out bound, etc ...). Elle apporte conseils, réflexions et recommandations en publicité, communication, promotion des ventes, stimulation de ses réseaux de distribution et de sa force de vente.
      </p>
      <p>
        Elle élabore et réalise directement la stratégie de création ainsi que les opérations promotionnelles qui lui sont confiées, en qualité de maître d’œuvre ou de mandataire du client suivant la nature des prestations demandées, définies ci-après, dans le cadre d’un contrat de collaboration ou au sein d’un devis qui en précise le cahier des charges.
      </p>
      <p>
        Dans le cas où l’agence agit en qualité de mandataire du client, notamment pour l’achat d’espaces publicitaire, un contrat de mandat spécifique sera rédigé entre les parties, conformément aux dispositions de la loi n° 93-122 du 29 janvier 1993. Le fait de lui confier l’une de ces opérations emporte acceptation pleine et entière des présentes conditions générales et notamment des exclusions et limites de responsabilités qu’elles prévoient.
      </p>
      <br>
      <h3 class="black--text">ARTICLE 2 : ELABORATION ET ACCEPTATION DES DEVIS</h3>
      <br>
      <p>
        Le devis est établi par l’Agence sur les indications et documents expressément fournis par le Client lors du brief ou des instructions.
      </p>
      <p>
        Toute omission ou toute indication erronée, imprécise ou incomplète de la part du Client engagera la responsabilité de celui-ci sur toutes les conséquences judiciaires, administratives, financières, matérielles ou autres qui pourraient en découler.
      </p>
      <p>
        Le devis ainsi établi mentionne les opérations confiées par le Client, leurs modalités d’exécution, une estimation des honoraires relatifs aux prestations de conseils ainsi que du coût des frais dits techniques.
      </p>
      <p>
        L’Agence n’est engagée qu’à compter de l’acceptation du devis par le client et dans la limite de la ou des opération(s) expressément mentionné(es). Cette acceptation doit se faire, soit par émission d’un bon de commande avec les références du devis correspondant, soit par émargement du devis dûment retourné à l’Agence. A défaut, tout commencement d’exécution de l’opération, versement d’honoraires, livraison de marchandises, vaut acceptation pleine et entière du devis de l’Agence et des présentes conditions générales.
      </p>
      <p>
        La validité du devis ainsi émis par l’Agence est de 1 mois, période au-delà de laquelle l’Agence se réserve le droit de refuser l’acceptation postérieure du client aux termes et coûts stipulés dans le dit devis. Toute modification des conditions et des prestations telles que déterminées au devis initial devra être faite par un avenant écrit et accepté par les deux parties.
      </p>
      <p>
        Dans le cas où, pour des raisons de planning et de rapidité d’exécution des opérations, l’agence n’a pas été en mesure de produire un devis préalable, ou que le client n’a pas eu le temps d’émettre un bon de commande avec les références correspondantes, avec émargement du devis dûment retourné à l’Agence, l’ordre donné par le client par écrit (email ou SMS) vaut commande. L’Agence s’engage dans ce cas à pratiquer une tarification en proportion avec les devis des travaux précédemment exécutés en l’absence de contrat ; ou si elle est liée contractuellement, selon les dispositions tarifaires qui y sont stipulées.
      </p>
      <br>
      <h3 class="black--text">ARTICLE 3 : CONTRAT </h3>
      <br>
      <p>
        En toute hypothèse, le contrat entre le client et l’Agence est réputé définitivement conclu par l’acceptation par le client du devis établi par l’Agence à compter de l’émission du bon de commande ou de la signature du devis ou de l’acte d’engagement.
      </p>
      <p>
        Cela étant, dans l’hypothèse où l’Agence et le Client envisageraient d’entretenir des relations plus durables de collaboration, il pourrait être rédigé un contrat de collaboration dont les conditions particulières primeraient, le cas échéant, les présentes conditions générales de ventes. Étant précisé que ces dernières demeureraient applicables en l’absence de stipulation particulière contraire.
      </p>
      <p>
        De la même manière, dans l’hypothèse où le client souhaiterait confier à l’Agence l’achat d’espaces publicitaires dans le cadre des opérations promotionnelles confiées, un contrat de mandat devrait être rédigé. Sous réserve des stipulations particulières qu’il pourrait contenir, il renverrait expressément aux présentes conditions générales et notamment à l’article 8 des présentes.
      </p>
      <br>
      <h3 class="black--text">ARTICLE 4 : PRIX ET CONDITIONS DE REGLEMENT </h3>
      <p>
        Les prestations intellectuelles liées au conseil et recommandation, donneront lieu à une facturation sous forme d’honoraires, au forfait ou au temps passé, selon la nature de l’opération commandée, de sa complexité, de son importance et des moyens à mettre en oeuvre. Hors les prestations intellectuelles, les travaux et frais techniques que CAB réalise ou fait réaliser en qualité de maître d’œuvre par les fournisseurs et sous-traitants, feront l'objet de devis estimatifs en fonction du coût des prestations considérées, du temps passé et/ou des contraintes rencontrées. Dans ces hypothèses, la rémunération de CAB, agissant en qualité de maître d’œuvre, est inclue dans les devis présentés au client.
      </p>
      <p>
        Les frais de déplacement et de voyage (au-delà de 30 kilomètres du siège social de l’agence) et d’hébergement des collaborateurs de l’Agence sont pris en charge par le client, sur présentation de justificatifs.
      </p>
      <p>
        S’agissant des frais d’affranchissements, l’Agence rend compte à l’annonceur à l’euro de l’engagement et du montant des dépenses.
      </p>
      <p>
        4-1/ Fixation du prix des prestations intellectuelles et des frais liés aux travaux techniques :
      </p>
      <ul>
        <li>Le prix des prestations est librement fixé entre les parties contractantes conformément aux honoraires convenus forfaitairement ou au temps passé dans le devis. </li>
        <li>Le montant du devis ainsi établi par l’Agence est ferme et non révisable dès son acceptation.</li>
        <li>Les prix et montants des honoraires ainsi fixés dans un devis ne valent que pour ledit devis, à l’exclusion de toute autre opération.</li>
        <li>L’Agence se réserve le droit de reporter toute nouvelle taxe et augmentation de taux. Le tarif indiqué étant révisable annuellement en fonction de l’indice du taux d’inflation des services, de l’évolution du volume des budgets alloués aux opérations commandées et du temps passé. </li>
        <li>La rémunération de l’Agence ne pourra être fonction des résultats des ventes de l’annonceur. </li>
        <li>Tout autre travail, prestation technique réalisés ou frais engagés par l’Agence, directement ou en qualité de maître d’œuvre, sera facturé forfaitairement, après approbation par le client d’un devis estimatif établi en fonction du coût des prestations nécessaires, du temps et/ou des contraintes de réalisation rencontrées.</li>
        <li>La rémunération des opérations d’achat d’espaces publicitaires, de prestations d’édition et de distribution d’imprimés publicitaires, effectuées par l’agence pour le compte du client en vertu d’un contrat de mandat, fera l’objet d’un forfait stipulé au sein du mandat. </li>
      </ul>
      <br>
      <p>
        4-2/ Conditions de paiement :
      </p>
      <ul>
        <li>Les factures sont réglées à 30 jours de la date de facture, sauf accords en place avec le client signé des deux parties et sous réserve des conditions de facturation des sous- traitants, fournisseurs et autres prestataires intervenant dans le cadre de l’opération commandée. </li>
        <li>Les frais d’acheminement et de valeur faciale peuvent faire l’objet d’avances (comprises entre 20% et 100% du montant des frais estimés au regard de le l’évaluation des remontées). Ces avances sont payables au comptant à l’acceptation du devis et seront intégralement restituées au Client en fin d’opération, sous réserve du règlement des factures par celui-ci. </li>
        <li>Les valeurs faciales et les avances de fonds sont réglées par chèque ou virement dès réception de la « lettre de levé de fond ». </li>
        <li>Il n’est pas prévu d’escompte en cas de paiement anticipé. </li>
      </ul>
      <br>
      <p>
        4-3/ Conséquences du non-paiement ou paiement partiel à échéance :
      </p>
      <br>
      <p>
        Le non-respect par le Client des conditions de paiement entraîne de plein droit la cessation de toute prestation.
        Tout défaut ou retard de paiement d’une facture échue, intégral ou partiel, entraînera automatiquement, outre la cessation de toute prestation de l’agence, l’exigibilité immédiate de toutes les sommes dues par le client au titre du contrat ou devis y afférent, sans préjudice des frais engagés pour le recouvrement de la créance et des majorations suivantes :
      </p>
      <ul>
        <li>Application de plein droit d’un intérêt de retard correspondant au taux d’intérêt légal en vigueur majoré de 50% (loi 92-1442 du 31/12/92) par jour de retard. </li>
        <li>Paiement d’une indemnité forfaitaire de 10% de la somme initialement due à titre de clause pénale, avec un minimum forfaitaire de 250 € par incident de paiement. </li>
      </ul>
      <br>
      <h3 class="black--text">ARTICLE 5 : RECLAMATION, ANNULATION OU REPORT </h3>
      <br>
      <p>
        Toute réclamation du Client relative à la conformité d’une prestation de service livrée ou effectuée par l’Agence dans le cadre de l’opération commandée devra, sous peine de déchéance, être formulée par écrit et adressée en recommandé avec AR au siège de l’agence dans un délai maximum de 8 jours à compter :
      </p>
      <ul style="list-style: none;">
        <li>- de la date d’émission de ladite recommandation.</li>
        <li>-	de la livraison ou réalisation dudit service.
          Après examen, l’Agence se réservera le droit de rembourser en tout ou partie le prix payé par le client ou d’offrir l’équivalent de la prestation faisant l’objet de la contestation.
        </li>
      </ul>
      <p>
        L’annulation ou le report d’un contrat à la demande du Client entraîne obligatoirement le paiement par celui-ci :
      </p>
      <ul style="list-style: none;">
        <li>-	des honoraires convenus aux termes du devis accepté ou du contrat conclu, .</li>
        <li>-	des frais et avances engagés et facturés par l’agence et tels que prévus au devis ou au contrat, </li>
        <li>-	Et ce, sans préjudice des intérêts au taux légal en vigueur échus entre le jour de la signature du devis ou de la conclusion du contrat et le jour où le client décide d’y mettre fin ou d’en reporter l’exécution. </li>
      </ul>
      <br>
      <h3 class="black--text">ARTICLE 6 : OBLIGATIONS DE CAB </h3>
      <br>
      <p>
        Il est expressément stipulé que l’ensemble des prestations mises à la charge de CAB ne constituent en aucune manière des obligations de résultat, l’agence étant exclusivement tenue, en tant qu’agence de conseil, à porter tous les soins visés à l’article 1137 du code civil aux missions qui lui sont confiées.
      </p>
      <p>
        L’Agence s’engage et s’oblige envers le Client à réaliser ses prestations de manière professionnelle, selon les règles et usages généralement reconnus par la profession et en fonction des éléments et données fournis par le Client. Toute opération sera pensée, créée et s’exécutera aussi efficacement que possible sous réserve des contraintes techniques et des limitations imposées par la Loi et les règlements.
      </p>
      <p>
        Elle s’engage également à ne pas divulguer les informations confidentielles, ou considérées comme telles par le Client, recueillies dans le cadre de la mise en oeuvre des services et prestations commandées. CAB prendra toutes les dispositions nécessaires auprès de son personnel, ou auprès d'entreprises auxquelles elle aurait recours, afin de conserver aux dites informations leur caractère confidentiel.
      </p>
      <p>
        En contrepartie de l’exclusivité mise à la charge du client pendant la durée de l’opération confiée, l’Agence s’engage, pendant la même durée, à ne pas effectuer d’actions de communication, de promotion ou marketing média et hors média en faveur de produits, services ou marques directement concurrents du Client, sauf accord écrit de ce dernier.
      </p>
      <p>
        Dans l’hypothèse où une collaboration entre CAB et d’autres marques directement concurrentes de celles objets du devis ou du contrat conclu avec le client préexisterait à celui- ci, l’Agence s’engagerait à en informer sans délai le client. Dans cette hypothèse, le Client pourra demander à CAB la mise en place d’une exclusivité sous forme d’une clause de non concurrence qu’il rémunérera sous la forme d’un forfait d’honoraires inclus au devis afférent à l’opération promotionnelle confiée.
      </p>
      <br>
      <h3 class="black--text">ARTICLE 7 : SOUS TRAITANCE </h3>
      <br>
      <p>
        L’Agence se réserve la faculté de sous-traiter tout ou partie de ses obligations à des tiers disposant des capacités nécessaires pour les prestations techniques qu’implique la réalisation de l’opération confiée.
      </p>
      <p>
        L’Agence n’est tenue à aucune obligation d’informer l’annonceur de l’existence des sous- traitants auxquels elle a recours, étant précisé que, dans cette hypothèse, les opérations ainsi sous-traitées sont exécutées sous sa responsabilité.
      </p>
      <br>
      <h3 class="black--text">ARTICLE 8 : OBLIGATIONS SPECIFIQUES A L’ACHAT D’ESPACES PUBLICITAIRES </h3>
      <br>
      <p>
        Dans tous les cas où l’Agence se verra confier par le client la mission d’achat d’espaces publicitaires ou la réalisation de prestations ayant pour objet l’édition ou la distribution d’imprimés publicitaires, un contrat de mandat devra être rédigé conformément aux dispositions de la loi n° 93-122 du 29 janvier 1993 en ses articles 20 à 27.
      </p>
      <p>
        Lorsque l’agence agira, non en qualité de maître d’œuvre, mais en qualité de mandataire, les produits visés seront tous les imprimés, du prospectus au catalogue, destinés à être distribués, que ce soit manuellement, dans les boîtes aux lettres, par publipostage personnalisé ou par mise à la libre disposition du public.
      </p>
      <p>
        Les prestations qui entreront dans le cadre du mandat pour les imprimés publicitaires sont l’impression, la personnalisation, le façonnage, la mise sous pli, le routage et les affranchissements.
      </p>
      <p>
        Seront exclues des stipulations du présent article les produits tels qu’affiches (y compris celles destinées à être apposées dans les points de ventes), les objets publicitaires et présentoirs qui ne sont pas distribués.
      </p>
      <p>
        Dans le cadre du mandat pour l’achat d’espace publicitaire, l’Agence négocie au nom et pour le compte du Client, les taux de remises qu’elle peut obtenir en fonction des barèmes et des conditions générales de ventes des supports publicitaires.
      </p>
      <p>
        L’approbation des plans médias par le Client engage la responsabilité de ce dernier quant aux ordres passés conformément à ces plans.
      </p>
      <p>
        L’agence n’est pas solidairement responsable avec le Client du paiement de l’achat d’espace du fait de son statut légal de mandataire. L’agence établit des devis estimatifs avant toute opération d’achat d’espace. Son statut de mandataire implique que l’accord des devis par le Client engage la responsabilité exclusive de ce dernier quant aux choix des fournisseurs, quant à l’acceptation des conditions générales de vente que lui soumettent ces derniers et quant à la bonne exécution des prestations.
      </p>
      <p>
        L’Agence mandataire peut être substituée par un tiers pour réaliser les prestations entrant dans le cadre du mandat, au nom et pour le compte du Client, conformément aux dispositions de l’article 1944 du Code civil. Dans le cas où elle le fait sans l’autorisation du mandant, sa responsabilité de mandataire demeure engagée. En Revanche, si elle reçoit pouvoir exprès du client pour ce faire, elle est purement et simplement déchargée de ses obligations à l’égard du client, à condition que le tiers substitué soit apte à exécuter le mandat.
      </p>
      <p>
        Conformément à l’article 23 de la loi du 29 janvier 1993 précitée, l’Agence informera le client des conditions de diffusion du message publicitaire et recueillera son accord sur les changements devant intervenir dans les conditions de diffusion du message.
      </p>
      <p>
        Conformément à la loi précitée interdisant à l’agence de percevoir, dans le cadre de l’achat d’espaces publicitaires, d’avantage ou rémunération d’une autre personne que le client mandant, l’Agence sera rémunérée sur ces opérations d’achat d’espaces moyennant la fixation d’une somme forfaitaire dans le contrat même de mandat pour les négociations tarifaires avec les supports et la passation d’ordres. Cette somme forfaitaire aura pour base le tarif horaire pratiqué par l’agence pour les autres prestations définies aux présentes.
      </p>
      <p>
        La facturation de cette somme et son règlement obéira aux conditions de paiements définies à l’article 4 des présentes (« PRIX ET CONDITIONS DE REGLEMENT »).
      </p>
      <p>
        Pour les factures de vente d’espace, le Client en effectuera directement le règlement auprès des vendeurs, sans que cela concerne l’agence.
      </p>
      <br>
      <h3 class="black--text">ARTICLE 9 : DELAIS D’EXECUTION DES PRESTATIONS </h3>
      <br>
      <p>
        L’Agence est tenue de réaliser les prestations demandées dans les délais convenus aux termes du devis accepté ou du contrat conclu avec le client, à compter à la fois de la signature du devis ou contrat et du moment où elle est en possession de tous les éléments nécessaires à la réalisation des prestations commandées et sous réserve de tout service additionnel requis par le Client après la signature du devis ou du contrat. Toutefois, elle n’est aucunement responsable des retards imputables :
      </p>
      <ul>
        <li>au Client , par exemple dans la signature des bons à tirer, dans la délivrance des accords de parution, dans la livraison des marchandises nécessaires à la gestion logistique des opérations ou dans la remise de tout autre élément prévu et nécessaire au bon déroulement des opérations, dans le paiement des sommes dues aux conditions prévues (acomptes, honoraires, provisions, avance de fonds, avance de trésorerie...). </li>
        <li>aux prestataires du client dans le cadre de l’exécution du/des devis, de la réalisation des prestations techniques nécessaires à l’opération commandée (telles que la fabrication et la livraison de dotations ou primes, les erreurs d’impression etc...). </li>
        <li>à tout autre évènement indépendant de son fait et empêchant le déroulement des opérations à la date prévue. </li>
      </ul>
      <p>
        D’une manière générale, dans les cas où le retard est imputable au Client, à ses fournisseurs ou sous-traitants, nonobstant la responsabilité qui lui incombera pour toutes les conséquences en résultant, celui-ci sera tenu de régler à l’agence les honoraires et frais de gestion que cette dernière aura été contrainte d’assumer.
      </p>
      <br>
      <h3 class="black--text">ARTICLE 10 : OBLIGATIONS DU CLIENT </h3>
      <br>
      <p>
        D’une manière générale, le Client s’engage à respecter toutes dispositions légales et réglementaires applicables à l’opération commandée à l’Agence et à faciliter l’exécution des prestations qu’elle lui confie dans le cadre de la conception et de la réalisation de la ou des opérations promotionnelles.
      </p>
      <ul>
        <li>Le Client s’engage à fournir par écrit à l’Agence les éléments complets concernant les services et prestations attendus ainsi que les informations relatives aux produits, marques etc dont il confie la promotion à l’Agence et qui apparaîtraient nécessaires à la mise en œuvre et au bon déroulement de l’opération. Si les éléments fournis par le Client s’avéraient erronés, incomplets ou imprécis, celui-ci devrait supporter toutes les conséquences directes ou indirectes en résultant, tant vis-à-vis des tiers que vis-à-vis de l’Agence. En toute hypothèse, cette dernière lui facturerait les frais qu’elle aurait inutilement engagés du fait des informations fournies. </li>
        <li>D’une manière générale, que ce soit dans le cadre d’une opération promotionnelle, marketing ou publicitaire, le client s’interdit de donner des éléments mensongers et s’engage à informer l’Agence de tout changement de libellé et/ou de contenu des marques ou produits faisant l’objet de l’opération promotionnelle et pouvant se répercuter sur la qualité des prestations fournies par l’Agence. </li>
        <li>Le Client sera tenu d’exécuter les engagements contractés par l’Agence, laquelle sera considérée comme son mandataire dans les limites du cadre contractuel expressément défini pour les achats d’espaces publicitaires ou de celles des opérations promotionnelles qui lui sont confiées. </li>
        <li>Sauf conditions particulières stipulées dans le cadre d’un contrat de collaboration conclu entre le Client et l’Agence et qui primeront les présentes sur ce point, le premier s’engage à confier à la seconde l’exclusivité des prestations de marketing et promotion relatives aux services, produits et marques pendant toute la durée de l’opération confiée et acceptée par contrat ou devis. </li>
      </ul>
      <br>
      <h3 class="black--text">ARTICLE 11 : DROITS DE PROPRIETE INTELLECTUELLE </h3>
      <br>
      <p>
        CAB demeure titulaire de ses droits d'auteur sur les conseils, recommandations et créations qu’elle peut donner ou réaliser dans le cadre des opérations qui lui sont confiées, en vertu des dispositions du code de la propriété intellectuelle (loi n° 92-597 du 1er juillet 1992).
      </p>
      <p>
        Toute exploitation ou utilisation postérieure à l’opération confiée à l’agence par le client des recommandations et créations, non prévue dans le contrat ou le devis, doit faire l'objet d'une autorisation expresse préalable de l’agence.
      </p>
      <p>
        Si la rémunération de cette cession de droits de créations, de reproduction ou de représentation n’a pas été contractuellement fixée dans le cadre de l’opération confiée par le client, elle fera l’objet d’une négociation ultérieure entre l’Agence et le client.
      </p>
      <p>
        Les éléments d'identification du client qui auraient été créés par l’Agence à la demande du premier et qui lui seraient cédés, notamment logo, sigle, marque et conditionnement feront l'objet d'un accord séparé prévoyant les modalités de cession des droits et la rémunération de celle-ci.
      </p>
      <p>
        L’agence peut également faire appel à des tiers pour la réalisation ponctuelle de contributions particulières telles que : photographie, illustration, film etc, et procédera à l’acquisition des droits d’auteur ou des droits voisins, en fonction de la diffusion prévue d’un commun accord avec le Client, pour chaque création réalisée en exécution du devis ou contrat conclu.
      </p>
      <p>
        Les droits des tiers (droit d'auteur, droits voisins) négociés par CAB, selon les nécessités de la communication et de l’opération confiée, en accord avec le client, seront refacturés à ce dernier, conformément au devis préalablement accepté par celui-ci.
      </p>
      <p>
        A l’expiration du devis ou du contrat, si le client souhaite poursuivre l’utilisation des créations réalisées par l’agence dans le cadre de l’opération exécutée, il sera subrogé dans les droits et obligations contractées par cette dernière avec les tiers et versera à l’agence les droits d’auteurs dont le montant sera négocié en fonction des utilisations envisagées.
      </p>
      <p>
        Les cessions stipulées ci-dessus ne couvrent pas les droits des personnes (droit à l'image, droit de la personnalité).
      </p>
      <p>
        En tout état de cause, le Client s’engage à garantir l’Agence contre les conséquences d’une utilisation des créations au-delà des limites contractuellement fixées entre l’agence et le client aux termes du devis ou contrat accepté.
      </p>
      <p>
        Il est expressément stipulé que l’ensemble des prestations mises à la charge de CAB ne constituent en aucune manière des obligations de résultat, l’agence étant exclusivement tenue, en tant qu’agence de conseil, à porter tous les soins visés à l’article 1137 du code civil aux missions qui lui sont confiées.
      </p>
      <p>
        L’Agence s’engage et s’oblige envers le Client à réaliser ses prestations de manière professionnelle, selon les règles et usages généralement reconnus par la profession et en fonction des éléments et données fournis par le Client. Toute opération sera pensée, créée et s’exécutera aussi efficacement que possible sous réserve des contraintes techniques et des limitations imposées par la Loi et les règlements.
      </p>
      <p>
        Elle s’engage également à ne pas divulguer les informations confidentielles, ou considérées comme telles par le Client, recueillies dans le cadre de la mise en oeuvre des services et prestations commandées. CAB prendra toutes les dispositions nécessaires auprès de son personnel, ou auprès d'entreprises auxquelles elle aurait recours, afin de conserver aux dites informations leur caractère confidentiel.
      </p>
      <p>
        En contrepartie de l’exclusivité mise à la charge du client pendant la durée de l’opération confiée, l’Agence s’engage, pendant la même durée, à ne pas effectuer d’actions de communication, de promotion ou marketing média et hors média en faveur de produits, services ou marques directement concurrents du Client, sauf accord écrit de ce dernier.
      </p>
      <p>
        Dans l’hypothèse où une collaboration entre CAB et d’autres marques directement concurrentes de celles objets du devis ou du contrat conclu avec le client préexisterait à celui- ci, l’Agence s’engagerait à en informer sans délai le client. Dans cette hypothèse, le Client pourra demander à CAB la mise en place d’une exclusivité sous forme d’une clause de non concurrence qu’il rémunérera sous la forme d’un forfait d’honoraires inclus au devis afférent à l’opération promotionnelle confiée.
      </p>
      <br>
      <h3 class="black--text">ARTICLE 12 : RESPONSABILITES </h3>
      <br>
      <p>
        Il est expressément rappelé que l’ensemble des prestations mises à la charge de CAB ne constitue en aucune manière des obligations de résultat, l’agence étant exclusivement tenue, en tant qu’agence de conseil, à porter tous les soins visés à l’article 1137 du code civil aux missions qui lui sont confiées.
      </p>
      <p>
        Seule l’exécution analytique, conceptuelle, rédactionnelle et créative des opérations (de promotion, de publicité, de stimulation de force de vente ou de réseaux, etc...) est confiée à l’Agence. L’Agence n’est donc responsable que du maniement de la mise en œuvre des informations et de leur mode de présentation.
      </p>
      <p>
        Celle-ci ne peut donc être tenue responsable d’une mauvaise exécution matérielle des autres prestataires requis dans le cadre des opérations qui lui sont confiées, notamment de l’immixtion dans son travail, d’une tierce personne choisie par le client.
      </p>
      <p>
        De façon générale, l’Agence ne peut en aucune façon répondre de ce qu’elle n’a pas exécuté conceptuellement. Elle ne saurait être tenue responsable de toute inexécution qui aurait pour origine un cas de force majeur ou cas fortuit, revêtant les caractères d’imprévisibilité, d’extériorité et d’irrésistibilité.
      </p>
      <p>
        En tout état de cause, sauf en cas de faute grave et dûment prouvée, l’Agence ne peut être tenue responsable envers le Client de tout dommage ou préjudice direct ou indirect pour les prestations qu’elle aura réalisées pour le compte du client. Elle ne garantit pas que l’utilisation ou le résultat de l’utilisation des recommandations, conseils, créations et autres prestations intellectuelles qu’elle fournit dans le cadre de l’opération confiée sera opportun vis-à-vis de la cible définie par le client, fiable en termes de retombées et correspondra aux attentes finales du client.
      </p>
      <p>
        Le Client garantit l’agence de toutes les conséquences d’une action qui trouverait sa source dans l’inexactitude des informations fournies sur ses produits ou services provenant par exemple d’une publicité trompeuse ou de nature à induire en erreur, ou d’une concurrence déloyale.
      </p>
      <p>
        Le Client est également seule responsable des informations qu’il transmet à l’agence portant notamment sur le nom, la composition, les qualités, les performances du produit ou du service faisant l’objet de l’opération promotionnelle confiée à l’agence.
      </p>
      <p>
        Il est également responsable des réglementations spécifiques à son activité.
      </p>
      <p>
        Dans tous les cas où la responsabilité de l’Agence serait engagée, pour quelle que cause et à quel que titre que ce soit, elle serait strictement limitée au remboursement des seules prestations de l’Agence, sans pouvoir en aucun cas excéder la somme de 5 000 € T.T.C. par opération.
      </p>
      <p>
        Dans le cas où le Client souhaiterait être garanti au-delà de cette somme, il lui appartiendrait de contracter une garantie spécifique auprès d’un assureur de son choix ou de donner préalablement des instructions écrites à l’Agence en ce sens, moyennant un supplément de prix à la charge du Client et sous réserve des possibilités de garantie en la matière.
      </p>
      <br>
      <h3 class="black--text">ARTICLE 13 : CLAUSE RESOLUTOIRE </h3>
      <br>
      <p>
        En cas d’inexécution totale ou partielle par l’agence ou le client de l’une quelconque des obligations mises à leur charge aux termes des présentes conditions générales ou des conditions particulières convenues entre elles, l’autre partie pourra, un mois après mise en demeure par lettre recommandée avec accusé de réception restée sans réponse ni effet, procéder à la résiliation de plein droit des relations contractuelles aux torts exclusifs de l’autre partie, sans préjudice de tous dommages et intérêts qu’elle pourrait être amenée à réclamer pour le préjudice subi.
      </p>
      <p>
        Sauf stipulation contraire, une indemnité forfaitaire fixée à 10% du montant du marché confié à l’agence sera versée par la partie défaillante à la partie créancière de l’obligation, nonobstant toute action judiciaire qui pourrait être menée du fait de la résiliation devant les juridictions compétentes aux fins d’obtenir des dommages et intérêts en réparation des préjudices subis.
      </p>
      <br>
      <h3 class="black--text">ARTICLE 14 : ATTRIBUTION DE COMPETENCE </h3>
      <br>
      <p>
        Tout litige portant sur la conclusion, l’interprétation, l’exécution ou la résolution des relations contractuelles entre les parties sera de la compétence exclusive des tribunaux de Nanterre (92).
      </p>
      <br>
      <h3 class="black--text">ANNEXES</h3>
      <h3 class="black--text">Liste des affiliés</h3>
      <br>
      <img src="../assets/img/anexe.png" width="100%" alt="anexe">
    </div>
  </v-container>
</template>

<script>
export default {
name: "CFGContent"
}
</script>

<style scoped>

</style>