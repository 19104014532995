<template>
  <div class="page-wrapper grey lighten-4">
    <Navbar />

    <v-container>
      <v-row class="py-4">
        <v-col
            sm="6"
            cols="12"
            class="d-flex flex-column align-start justify-center"
        >
          <v-btn tile depressed class="subtitle-2 text-lowercase pl-0" to="/">
            <v-icon class="font-weight-regular pr-2" color="black"
            >mdi-arrow-left</v-icon
            >
            Retour
          </v-btn>
          <p class="text-h6 ma-0 pl-3" style="border-left:4px solid #000;">
            Corporate Le Beau Vivre
          </p>
        </v-col>

        <v-col
            sm="6"
            cols="12"
            class="d-flex align-end justify-sm-end justify-start"
        >
          <v-row no-gutters>
            <v-col class="mt-2">
              <v-btn
                  class="w-100 plateforme-marque-btn"
                  :class="{ 'black-btn': plateforme_marque_show }"
                  @click="download_platform_brand_modal()"
                  tile
                  outlined
                  depressed
              >
                <v-icon dark>mdi-download</v-icon> Plateforme de marque
              </v-btn>
            </v-col>

            <v-col class="mt-2">
              <v-btn
                  class="w-100 charte-btn"
                  :class="{ 'black-btn': charte_show }"
                  @click="download_graphical_charter_modal()"
                  tile
                  outlined
                  depressed
              >
                <v-icon dark>mdi-download</v-icon> CHARTE GRAPHIQUE
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row v-if="campagnes_show" class="white py-4 pb-12">
        <v-col cols="12" class="d-flex align-center justify-start">
          <v-btn
              class="px-2"
              :class="{ 'black-btn': campagnes_data_show }"
              style="font-size: 12px;"
              tile
              outlined
              depressed
              @click="show_campagnes()"
          >Campagnes</v-btn
          >
          <v-btn
              class="px-2"
              :class="{ 'black-btn': films_data_show }"
              style="font-size: 12px;"
              tile
              outlined
              depressed
              @click="show_films()"
          >Films</v-btn
          >
          <v-btn
              class="px-2"
              :class="{ 'black-btn': exemple_realisation_data_show }"
              style="font-size: 12px;"
              tile
              outlined
              depressed
              @click="show_exemple_realisation()"
          >Exemple de realisations</v-btn
          >
        </v-col>

        <v-col>
          <v-data-table
              v-if="campagnes_data_show"
              :headers="campagnes_headers"
              :items="campagnes_data"
              :disable-sort="true"
              :hide-default-footer="true"
              :loading="loading"
              loading-text="Loading... Please wait"
              class="elevation-1"
          >
            <template v-slot:item="row">
              <tr>
                <td @click="show_beau_vivre_modal(row.item)" style="cursor: pointer">{{ row.item.name }}</td>
                <td @click="show_beau_vivre_modal(row.item)" style="cursor: pointer">
                  Du {{ row.item.start_date | change_date_format() }} au
                  {{ row.item.end_date | change_date_format() }}
                </td>
                <td @click="show_beau_vivre_modal(row.item)" style="cursor: pointer">{{ row.item.claim | truncate(20) }}</td>
                <td @click="show_beau_vivre_modal(row.item)" style="cursor: pointer">{{ row.item.tools_count }}</td>
                <td>
                   <v-icon @click="campagne_detail(row.item)" color="black">mdi-eye</v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>

          <v-data-table
              v-if="films_data_show"
              :headers="films_headers"
              :items="films_data"
              :disable-sort="true"
              :hide-default-footer="true"
              :loading="loading"
              loading-text="Loading... Please wait"
              class="elevation-1"
          >
            <template v-slot:item="row">
              <tr>
                <td>{{ row.item.name }}</td>
                <td>
                  <v-btn
                      icon
                      small
                      color="black"
                      :href="`${row.item.url}`"
                      target="_blank"
                  >
                    <v-icon dark>mdi-eye</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>

          <v-data-table
              v-if="exemple_realisation_data_show"
              :headers="exemple_realisation_headers"
              :items="exemple_realisation_data"
              :disable-sort="true"
              :hide-default-footer="true"
              :loading="loading"
              loading-text="Loading... Please wait"
              class="elevation-1"
          >
            <template v-slot:item="row">
              <tr>
                <td>{{ row.item.name }}</td>
                <td>
                    <v-icon @click="$router.push({ name: 'ExempleDetail', params: { exemple: row.item }})" color="black">mdi-eye</v-icon>
                </td>
                <td>
                  <v-btn
                      icon
                      small
                      color="black"
                      @click="download_file_modal(row.item)"
                  >
                    <v-icon dark>mdi-download</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
          <template>
            <div class="text-right">
              <v-pagination
                  color="grey"
                  v-model="current_page"
                  :length="last_page"
                  @input="go_to_page"
                  prev-icon="mdi-menu-left"
                  next-icon="mdi-menu-right"
              ></v-pagination>
            </div>
          </template>
        </v-col>

        <v-dialog v-model="beau_vivre_show" width="1200">
          <v-card class="campagne-outils-modal">
            <v-card-title class="modal-title ">
              <v-container class="pa-0">
                <v-row no-gutters>
                  <v-col cols="1" class="d-flex align-center justify-start">
                    <v-btn
                        icon
                        class="close-modal"
                        @click="beau_vivre_show = false"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-col>

                  <v-col
                      md="3"
                      sm="5"
                      cols="12"
                      class="d-flex py-2 align-center justify-md-start justify-center"
                  >
                    {{ selected_campaign.name }}
                  </v-col>

                  <v-col
                      md="3"
                      sm="6"
                      cols="12"
                      class="d-flex py-2 align-center justify-md-start justify-center"
                  >
                    Du {{ selected_campaign.start_date | change_date_format() }} au
                    {{ selected_campaign.end_date | change_date_format() }}
                  </v-col>

                  <v-col
                      md="5"
                      sm="12"
                      cols="12"
                      class="d-flex py-2 align-center justify-md-end justify-center"
                  >
                    <v-btn class="black-btn" tile outlined depressed href="mailto:contact@noblessa.com " target="_blank">
                      <v-icon class="mr-2" style="font-size:16px;">
                        mdi-help-circle
                      </v-icon>
                      Commandes ou demandes speciales
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>

            <v-card-text class="grey lighten-2 py-0">
              <v-container>
                <v-row>
                  <v-col md="6" cols="12"> </v-col>

                  <v-col md="6" cols="12">
                    <p
                        class="d-flex justify-center align-center font-weight-bold black--text text-subtitle-1"
                    >
                      Production - Impression
                    </p>
                  </v-col>
                </v-row>

                <v-row class="white">
                  <v-col cols="12">
                    <v-simple-table
                        dense
                        fixed-header
                        height="500px"
                        class="outils-table"
                    >
                      <template v-slot:default>
                        <thead>
                        <tr>
                          <th>Outils</th>
                          <th>Afficher</th>
                          <th>Télécharger</th>
                          <th>Commander</th>
                          <th>Quantité</th>
                          <th>Prix</th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr
                            v-for="outil in beau_vivre_modal_table"
                            :key="outil.id"
                        >
                          <td class="font-weight-medium">
                            {{ outil.name }}
                          </td>
                          <td>
                            <v-btn icon small color="black" @click="detail_tool(outil)" >
                              <v-icon dark>mdi-eye</v-icon>
                            </v-btn>
                          </td>
                          <td>
                            <v-btn icon small color="black" @click="download_file_tool_modal(outil)">
                              <v-icon dark>mdi-download</v-icon>
                            </v-btn>
                          </td>
                          <td>
                            <v-btn icon small color="black">
                              <v-checkbox @change="handel_outil(outil)" v-model="outil.active" color="black"></v-checkbox>
                            </v-btn>
                          </td>
                          <td>
                            <v-select
                                dense
                                class="mx-auto pa-0"
                                style="width:100px;"
                                height="25"
                                color="black"
                                :disabled="!outil.active"
                                :items="quantity_select"
                                v-model="outil.quantity"
                            ></v-select>
                          </td>
                          <td class="font-weight-medium">
                            {{ outil.prix }} €
                          </td>
                        </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions class="grey lighten-2">
              <v-container>
                <v-row>
                  <v-col cols="12" class="d-flex justify-center align-center">
                    <v-btn class="black-btn" tile outlined depressed @click="confirme_campagne">
                      Confirmer
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="merci_modal_show" width="500">
						<v-card class="merci-modal">
							<v-card-text>
								<v-container class="d-flex flex-column justify-center align-center">
									<v-icon class="font-weight-medium black--text text-h1" color="black">mdi-check-circle-outline</v-icon>
									<p class="font-weight-medium black--text text-h6">Merci!</p>
									<p class="font-weight-medium black--text text-subtitle-1">Votre commande a bien été prise en compte</p>
								</v-container>
							</v-card-text>

							<v-card-actions>
								<v-container>
									<v-row>
										<v-col cols=12 class="d-flex justify-center align-center">
											<v-btn class="black-btn" @click="merci_modal_show=false" tile outlined depressed>
												FERMER
											</v-btn>
										</v-col>
									</v-row>
								</v-container>
							</v-card-actions>
						</v-card>
				</v-dialog>

      </v-row>
    </v-container>

    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Navbar from "@/components/Navbar.vue";
import ApiService from "@/services/api.service";
import moment from "moment";
import axios from "axios";
import Vue from "vue";
import JwtService from "@/services/jwt.service";


export default {
  filters: {
    change_date_format(date) {
      const result = moment(date)
          .locale("fr")
          .format("DD/MM/YY");
      return result;
    },
    truncate(text, stop, clamp) {
      return text.slice(0, stop) + (stop < text.length ? clamp || "..." : "");
    },
  },
  data() {
    return {
      current_page: 1,
      last_page: 1,
      loading: false,

      campagnes_show: true,
      beau_vivre_show: false,
      merci_modal_show:false,
      selected_campaign: {},

      campagnes_data_show: true,
      films_data_show: false,
      exemple_realisation_data_show: false,

      campagnes_headers: [
        { text: "Campagne", value: "campagne", class: "black--text" },
        { text: "Date", value: "date", class: "black--text" },
        { text: "Claim", value: "claim", class: "black--text" },
        { text: "Outils", value: "outils", class: "black--text" },
        { text: "Afficher", class: "black--text" },
      ],

      campagnes_data: [],

      films_headers: [
        { text: "Films", value: "films", class: "black--text" },
        { text: "Afficher", class: "black--text" },
      ],

      films_data: [],

      exemple_realisation_headers: [
        { text: "Outils", value: "outil", class: "black--text" },
        { text: "Afficher", class: "black--text" },
        { text: "Télécharger", class: "black--text" },
      ],

      exemple_realisation_data: [],

      beau_vivre_modal_table: [],

      quantity_select: ["", 10, 50, 100, 200, 500, 1000],
    };
  },

  components: {
    Footer,
    Navbar,
  },
  mounted() {
    this.show_campagnes();
  },
  // api/v1/agency/tgl_campaign
  methods: {
    fetch_campaign(url = `${this.$app_url}/api/v1/agency/tgl_campaign`) {
      this.loading = true;
      ApiService.get(url)
          .then((response) => {
            this.campagnes_data = response.data.data;
            this.current_page = response.data.current_page;
            this.last_page = response.data.last_page;
            if (response.data.message) {
              this.$emit("flash-success-snackbar", response.data.message);
            }
          })
          .catch((err) => {
            this.$emit("flash-warning-snackbar", err.response.data.message);
          })
          .finally(() => {
            this.loading = false;
          });
    },

    fetch_films(url = `${this.$app_url}/api/v1/agency/tgl_campaign/film`) {
      this.loading = true;
      ApiService.get(url)
          .then((response) => {
            this.films_data = response.data.data;
            this.current_page = response.data.current_page;
            this.last_page = response.data.last_page;
            if (response.data.message) {
              this.$emit("flash-success-snackbar", response.data.message);
            }
          })
          .catch((err) => {
            this.$emit("flash-warning-snackbar", err.response.data.message);
          })
          .finally(() => {
            this.loading = false;
          });
    },
    fetch_exemple_realisation(
        url = `${this.$app_url}/api/v1/agency/tgl_campaign/example_of_realization`
    ) {
      this.loading = true;
      ApiService.get(url)
          .then((response) => {
            this.exemple_realisation_data = response.data.data;
            this.current_page = response.data.current_page;
            this.last_page = response.data.last_page;
            if (response.data.message) {
              this.$emit("flash-success-snackbar", response.data.message);
            }
          })
          .catch((err) => {
            this.$emit("flash-warning-snackbar", err.response.data.message);
          })
          .finally(() => {
            this.loading = false;
          });
    },
    show_beau_vivre_modal(campaign) {
      this.loading = true;
      let url = `${this.$app_url}/api/v1/agency/tgl_campaign/${campaign.id}/tool`;
      this.selected_campaign = null;
      ApiService.get(url)
          .then((response) => {
            //this.beau_vivre_modal_table = response.data.data;
            this.beau_vivre_modal_table = [];
            (response.data.data).forEach((outil)=> {
              this.beau_vivre_modal_table.push({
                id: outil.id,
                name: outil.name,
                quantity: null,
                active: false,
                file: outil.visualKey,
                url: "/test",
                prix: outil.price
              });
            })
            if (response.data.message) {
              this.$emit("flash-success-snackbar", response.data.message);
            }
          }).then(()=>{

        this.beau_vivre_show = true;
        this.selected_campaign = campaign;
      })
          .catch((err) => {
            this.$emit("flash-warning-snackbar", err.response.data.message);
          })
          .finally(() => {
            this.loading = false;
          });
    },

    hide_all() {
      this.campagnes_data_show = false;
      this.films_data_show = false;
      this.exemple_realisation_data_show = false;
    },

    show_campagnes() {
      this.hide_all();
      this.campagnes_data_show = true;
      this.fetch_campaign();
    },

    show_films() {
      this.hide_all();
      this.films_data_show = true;
      this.fetch_films();
    },

    show_exemple_realisation() {
      this.hide_all();
      this.exemple_realisation_data_show = true;
      this.fetch_exemple_realisation();
    },
    go_to_page(page) {
      if (this.campagnes_data_show) {
        this.fetch_campaign(
            `${this.$app_url}/api/v1/agency/tgl_campaign?page=${page}`
        );
      }
      if (this.films_data_show) {
        this.fetch_films(
            `${this.$app_url}/api/v1/agency/tgl_campaign/film?page=${page}`
        );
      }
      if (this.exemple_realisation_data_show) {
        this.fetch_exemple_realisation(
            `${this.$app_url}/api/v1/agency/tgl_campaign/example_of_realization?page=${page}`
        );
      }
    },
    download_graphical_charter_modal() {
      this.$confirm(
          {
            message: `Voulez-vous télécharger le fichier ?`,
            button: { no: 'Non', yes: 'Oui' },
            callback: confirm => {
              if (confirm) {
                this.loading = true;
                axios({
                  url: `${this.$app_url}/api/v1/agency/tgl_campaign/file/graphical_charter`,
                  method: "GET",
                  responseType: "blob",
                }).then((response) => {
                  const ext = response.data.type.split("/")[1];
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", `charte graphique.${ext}`);
                  document.body.appendChild(link);
                  link.click();
                }).catch((err) => {
                  this.$emit("flash-warning-snackbar", err.response.request.statusText);
                }).finally(() => {
                  this.loading = false;
                });
              }
            }
          }
      )

    },
    download_platform_brand_modal() {
      this.$confirm(
          {
            message: `Voulez-vous télécharger le fichier ?`,
            button: { no: 'Non', yes: 'Oui' },
            callback: confirm => {
              if (confirm) {
                this.loading = true;
                axios({
                  url: `${this.$app_url}/api/v1/agency/tgl_campaign/file/platform_brand`,
                  method: "GET",
                  responseType: "blob",
                }).then((response) => {
                  const ext = response.data.type.split("/")[1];
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", `plateform de marque.${ext}`);
                  document.body.appendChild(link);
                  link.click();
                }).catch((err) => {
                  this.$emit("flash-warning-snackbar", err.response.request.statusText);
                }).finally(() => {
                  this.loading = false;
                });
              }
            }
          }
      )

    },
    download_file_tool_modal(tool) {
      this.$confirm(
          {
            message: `Voulez-vous télécharger le fichier ?`,
            button: { no: 'Non', yes: 'Oui' },
            callback: confirm => {
              if (confirm) {
                this.loading = true;
                axios({
                  url: `${this.$app_url}/api/v1/user/file/${tool.file.id}`,
                  method: "GET",
                  responseType: "blob",
                }).then((response) => {
                  const ext = response.data.type.split("/")[1];
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", `${tool.file.name}.${ext}`);
                  document.body.appendChild(link);
                  link.click();
                }).catch((err) => {
                  this.$emit("flash-warning-snackbar", err.response.request.statusText);
                }).finally(() => {
                  this.loading = false;
                });
              }
            }
          }
      )
    },

    download_file_modal(item) {
      this.$confirm(
          {
            message: `Voulez-vous télécharger le fichier ?`,
            button: { no: 'Non', yes: 'Oui' },
            callback: confirm => {
              if (confirm) {
                this.loading = true;
                axios({
                  url: `${this.$app_url}/api/v1/user/file/${item.visualKey.id}`,
                  method: "GET",
                  responseType: "blob",
                }).then((response) => {
                  const ext = response.data.type.split("/")[1];
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", `${item.visualKey.name}.${ext}`);
                  document.body.appendChild(link);
                  link.click();
                }).catch((err) => {
                  this.$emit("flash-warning-snackbar", err.response.request.statusText);
                }).finally(() => {
                  this.loading = false;
                });
              }
            }
          }
      )

    },
    handel_outil(outil) {
      this.beau_vivre_modal_table.filter(item => {
        if(item.id == outil.id){
          if(outil.active){
            return item.quantity = 10;
          }
          if(!outil.active){
            return item.quantity = null;
          }
        }
      })
    },
    confirme_campagne(){
        let outils_confirmes = [];
        this.beau_vivre_modal_table.forEach(outil => {
            if(outil.active){
               outils_confirmes.push(
                   {
                    "id": outil.id,
                    "quantity": outil.quantity
                    }
               )
            } 
        });
       
        const formData = {};
        formData.tools =  outils_confirmes;
        formData.productId = this.selected_campaign.id;

        this.loading = true;
        let url = `${this.$app_url}/api/v1/agency/order/tgl_campaign`;
        Vue.axios.defaults.headers.common = {
            'X-Requested-With': 'XMLHttpRequest',
            "Authorization":`Bearer ${JwtService.getToken().token}`,
            'Content-Type': 'application/json',
        };
        ApiService.post(url, formData)
            .then((resp) => {
                this.beau_vivre_show = false;
                this.merci_modal_show = true
                this.$emit('flash-success-snackbar', resp.data.message);
            }).catch((err) => {
                this.$emit("flash-warning-snackbar", err.response.data.message);
            }).finally(() => {
                this.loading = false;
            });
    },
    commandes_demandes_speciales(){
      alert("mailto commandes demandes speciales");
      // mailto
    },

    detail_tool(tool) {
      this.$router.push({
        name: 'TglOutilDetail',
        params: { tool: tool },
      })
    },

    campagne_detail(item) {
      this.$router.push({
        name: 'CampagneDetail',
        params: { campagne: item },
      })
    }
  },

}

</script>

<style lang="scss">
.v-dialog {
  .facture-modal {
    img {
      width: 100%;
      height: auto;
    }

    .modal-title {
      &:after {
        content: none;
      }

      font-size: 16px;
      text-transform: initial;
      line-height: 1em;
      font-weight: 500;
    }

    .close-modal {
      position: relative;
      left: 0;
      top: 0;
      color: #000 !important;
    }
  }
}

.informations-btn,
.historique-btn {
  width: 100%;
}
</style>
