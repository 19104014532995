<template>

  <div class="page-wrapper grey lighten-4">
    <Navbar/>

    <v-container>
      <v-row class="py-4">
        <v-col sm="8" cols="12" class="d-flex flex-column align-start justify-center">
          <v-btn to="/" tile depressed class="subtitle-2 text-lowercase pl-0">
            <v-icon class="font-weight-regular pr-2" color="black">mdi-arrow-left</v-icon> Retour
          </v-btn>
          <p class="text-h6 ma-0 pl-3" style="border-left:4px solid #000;">Mes campagnes commerciales</p>
        </v-col>

        <v-col sm="4" cols="12" class="d-flex align-end actions-btn-container">
          <v-btn class="black-btn panorama-btn" :class="{'active-btn' : panorama_modal_show}" tile outlined depressed @click="showPanoramaModal()">
            <v-icon dark>mdi-download</v-icon> Panorama media
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="white py-4 pb-12">
        <v-col>
          <v-data-table class="campagnes-table elevation-1" :headers="headers" :items="data" :disable-sort="true" :hide-default-footer="true" :loading="loading" loading-text="Loading... Please wait">
            <template v-slot:item="row">
              <tr>
                <td>{{row.item.name}}</td>
                <td>Du {{ parseDate(row.item.start_date) }} au {{ parseDate(row.item.end_date) }}</td>
                <td v-if="row.item.claim.length < 40">
                  {{row.item.claim}}
                </td>
                <td v-else>
                  {{ row.item.claim.substring(0,40)+"  ..." }}
                </td>
                <td>
                  <v-btn icon small color="black" @click="campagne_detail(row.item)">
                    <v-icon dark>mdi-eye</v-icon>
                  </v-btn>
                </td>
                <td class="font-weight-bold">
                  <v-btn outlined depressed small color="white" @click="show_outils_modal(row.item)">
                    {{row.item.tools_count}}
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>

          <v-col>
            <template>
              <div class="text-right">
                <v-pagination
                    color="grey"
                    v-model="current_page"
                    :length="last_page"
                    @input="go_to_page"
                    prev-icon="mdi-menu-left"
                    next-icon="mdi-menu-right"
                ></v-pagination>
              </div>
            </template>
          </v-col>


          <v-dialog v-model="campagne_modal_show" width="700">
            <v-card class="campagne-modal">
              <v-card-title class="modal-title">
                <v-container class="pa-0">
                  <v-row no-gutters>
                    <v-col cols=1 class="d-flex align-center justify-start">
                      <v-btn icon class="close-modal" @click="campagne_modal_show = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-col>

                    <v-col sm="5" cols="12" class="d-flex align-center justify-sm-start justify-center">
                      {{campagne_modal_info.name}}
                    </v-col>

                    <v-col sm="1" cols="12" class="d-flex align-center justify-center">
                      <v-btn icon small color="black" @click="download_file()">
                        <v-icon dark>mdi-download</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-title>

              <v-card-text>
                <v-container class="ma-0 pa-0">
                  <v-row  class="white py-4">
                    <v-col v-if="campagne_modal_info.ext === 'pdf'">
                      <div class="pdfobject-container">
                        <iframe :src="campagne_modal_info.file" type="application/pdf" width="100%" height="400" style="overflow: auto;">
                        </iframe>
                      </div>
                    </v-col>
                    <v-col class="d-flex flex-column align-center justify-center" v-else-if="campagne_modal_info.ext === 'zip'">
                      <p class="text-h6 ma-0 mb-6">Aucune visualisation possible</p>
                      <span class="mb-6">
                        Cliquer sur le bouton pour télécharger le fichier.
                      </span>
                      <v-btn
                          class="ma-2 white--text"
                          fab
                          color="grey darken-4"
                          @click="download_file(tool)"
                      >
                        <v-icon dark>
                          mdi-download
                        </v-icon>
                      </v-btn>
                    </v-col>
                    <v-col v-else>
                      <img :src="campagne_modal_info.file" alt="">
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-dialog>

          <v-dialog v-model="campagne_tool_modal_show" width="700">
            <v-card class="campagne-modal">
              <v-card-title class="modal-title">
                <v-container class="pa-0">
                  <v-row no-gutters>
                    <v-col cols=1 class="d-flex align-center justify-start">
                      <v-btn icon class="close-modal" @click="campagne_tool_modal_show = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-title>

              <v-card-text>
                <v-container class="ma-0 pa-0">
                  <v-row  class="white py-4">
                    <v-col v-if="campagne_modal_info.ext === 'pdf'">
                      <div class="pdfobject-container">
                        <iframe :src="campagne_modal_info.file" type="application/pdf" width="100%" height="400" style="overflow: auto;">
                        </iframe>
                      </div>
                    </v-col>
                    <v-col class="d-flex flex-column align-center justify-center" v-else-if="campagne_modal_info.ext === 'zip'">
                      <p class="text-h6 ma-0 mb-6">Aucune visualisation possible</p>
                      <span class="mb-6">
                        Cliquer sur le bouton pour télécharger le fichier.
                      </span>
                      <v-btn
                          class="ma-2 white--text"
                          fab
                          color="grey darken-4"
                          @click="download_file(tool)"
                      >
                        <v-icon dark>
                          mdi-download
                        </v-icon>
                      </v-btn>
                    </v-col>
                    <v-col v-else>
                      <img :src="campagne_modal_info.file" alt="">
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-dialog>


          <v-dialog v-model="outils_modal_show" width="1200">
            <v-card class="campagne-outils-modal">
              <v-card-title class="modal-title ">
                <v-container class="pa-0">
                  <v-row no-gutters>
                    <v-col cols=1 class="d-flex align-center justify-start">
                      <v-btn icon class="close-modal" @click="outils_modal_show = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-col>

                    <v-col md="3" sm="5" cols=12 class="d-flex py-2 align-center justify-md-start justify-center">
                      {{outils_modal_info.campagne_name}}
                    </v-col>

                    <v-col md="3" sm="6" cols=12 class="d-flex py-2 align-center justify-md-start justify-center">
                      {{outils_modal_info.date}}
                    </v-col>

                    <v-col md="5" sm="12" cols=12 class="d-flex py-2 align-center justify-md-end justify-center">
                      <v-btn href="mailto:d.poupinel@agencetaxi.com" class="black-btn" tile outlined depressed>
                        <v-icon class="mr-2" style="font-size:16px;">
                          mdi-help-circle
                        </v-icon>
                        Commandes ou demandes speciales
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-title>

              <v-card-text class="grey lighten-2 py-0">
                <v-container>
                  <v-row>
                    <v-col md="6" cols=12>
                    </v-col>

                    <v-col md="6" cols=12>
                      <p class="d-flex justify-center align-center font-weight-bold black--text text-subtitle-1">Production - Impression</p>
                    </v-col>
                  </v-row>

                  <v-row class="white">
                    <v-col cols=12>
                      <v-simple-table dense fixed-header class="outils-table">
                        <template v-slot:default>
                          <thead>
                          <tr>
                            <th>Outils</th>
                            <th>Afficher</th>
                            <th>Télécharger</th>
                            <th>Commander</th>
                            <th>Quantité</th>
                            <th>Prix</th>
                          </tr>
                          </thead>

                          <tbody>
                          <tr v-for="outil in outils_table" :key="outil.id" :class="{'gold--text':outil.is_personalized==1}">
                            <td style="width: 30%" class="font-weight-medium">{{ outil.name }}</td>
                            <td style="width: 10%">
                              <v-btn icon small color="black">
                                <v-icon @click="show_campagne_tool_modal(outil)" dark>mdi-eye</v-icon>
                              </v-btn>
                            </td>
                            <td style="width: 10%">
                              <v-btn icon small color="black">
                                <v-icon @click="download_tool_file(outil)" dark>mdi-download</v-icon>
                              </v-btn>
                            </td>
                            <td style="width: 10%" v-if="outil.format_tool && outil.format_tool.type === 'Free'">
                              <v-btn icon small color="black">
                                <v-checkbox color="black" disabled></v-checkbox>
                              </v-btn>
                            </td>
                            <td style="width: 10%" v-else>
                              <v-btn icon small color="black">
                                <v-checkbox @click="click_command_checkbox(outil)" color="black"></v-checkbox>
                              </v-btn>
                            </td>
                            <td v-if="outil.format_tool && outil.format_tool.type === 'Free'" style="width: 20%">

                            </td>

                            <td v-else style="width: 20%">
                              <v-select :disabled="!outil.active" @change="update_quantity(outil.id)" v-model="quantity[outil.id]" :error-messages="validation_errors[`tools.${index}.quantity`]" dense class="mx-auto pa-0" style="width:100px;" height="25" color="black" item-value="quantity" item-text="quantity" :items="outil.formats"></v-select>
                            </td>

                            <td v-if="outil.format_tool && outil.format_tool.type === 'Free'" style="width: 10%"  class="font-weight-medium"></td>
                            <td v-else style="width: 10%"  class="font-weight-medium">{{calc_quantity(outil)   }}</td>
                          </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>

                    <v-col lg="3" md="4" sm="6" cols=12 class="d-flex flex-row justify-center align-center font-weight-medium black--text">
                      <div class="mr-2 circle circle-black"></div>
                      Outils générique campagne
                    </v-col>

                    <v-col lg="3" md="4" sm="6" cols=12 class="d-flex flex-row justify-center align-center font-weight-medium black--text">
                      <div class="mr-2 circle circle-gold"></div>
                      Outils personnalisé point de vente
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions class="grey lighten-2">
                <v-container>
                  <v-row>
                    <v-col cols=12 class="d-flex justify-center align-center">
                      <v-btn class="black-btn" @click="place_order()" tile outlined depressed>
                        Confirmer
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-actions>
            </v-card>
          </v-dialog>


          <v-dialog v-model="panorama_modal_show" width="1000">
            <v-card class="panorama-modal">
              <v-card-title class="modal-title ">
                <v-container class="pa-0">
                  <v-row no-gutters>
                    <v-col cols=1 class="d-flex align-center justify-start">
                      <v-btn icon class="close-modal" @click="panorama_modal_show = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-col>

                    <v-col sm="4" cols=12 class="d-flex align-center justify-sm-start justify-center">
                      Panorama média
                    </v-col>

                    <v-col sm="3" cols=12 class="d-flex align-center font-weight-bold justify-sm-start justify-center">
                      {{get_profile}}
                    </v-col>

                    <v-col sm="4" cols=12 class="d-flex align-center justify-sm-end justify-center">
                      1/9
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-title>

              <v-card-text>
                <v-container class="ma-0 pa-0 justify-center align-center d-flex">
                  <img src="~@/assets/img/panorama.png" alt="">
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-container>
                  <v-row>
                    <v-col sm="6" cols=12 class="d-flex justify-sm-start justify-center align-center">
                      Affichage_urbain_radio_presse
                    </v-col>

                    <v-col sm="6" cols=12 class="d-flex justify-sm-end justify-center align-center">
                      Mise à jour : 02/02/2021
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="merci_modal_show" width="500">
            <v-card class="merci-modal">
              <v-card-text>
                <v-container class="d-flex flex-column justify-center align-center">
                  <v-icon class="font-weight-medium black--text text-h1" color="black">mdi-check-circle-outline</v-icon>
                  <p class="font-weight-medium black--text text-h6">Merci!</p>
                  <p class="font-weight-medium black--text text-subtitle-1">Votre commande a bien été prise en compte</p>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-container>
                  <v-row>
                    <v-col cols=12 class="d-flex justify-center align-center">
                      <v-btn class="black-btn" @click="merci_modal_show=false" tile outlined depressed>
                        FERMER
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>

      </v-row>

    </v-container>

    <Footer/>
  </div>

</template>

<script>
import Footer from '@/components/Footer.vue'
import Navbar from '@/components/Navbar.vue'
import ApiService from '@/services/api.service';
import axios from "axios";
import moment from "moment";

export default {
  mounted(){
    this.fetchData(`${this.$app_url}/api/v1/agency/commercial_campaign`);
  },

  computed: {
    get_profile() {
      if (localStorage.getItem('set_profile')) {
        return localStorage.getItem('set_profile')
      }
      else {
        return null
      }
    }
  },

  data() {
    return {
      panorama_modal_show:false,
      campagne_modal_show:false,
      campagne_tool_modal_show: false,
      outils_modal_show:false,
      merci_modal_show:false,

      current_page: 1,
      last_page: 1,
      loading: false,

      validation_errors: {},



      headers: [
        { text: 'Campagne', value: 'name', class:"black--text"},
        { text: 'Période de la campagne', value: 'periode_campagne', class:"black--text"},
        { text: 'Claim', value: 'claim', class:"black--text" },
        { text: 'Afficher', class:"black--text"},
        { text: 'Outils', value: 'tools_count', class:"black--text"},
      ],

      data: [],
      outils_table: [],

      campagne_modal_info: {
        name:null,
        file:null,
        ext:null,
      },

      outils_modal_info: {
        campagne_id:null,
        campagne_name:null,
      },

      outil_order_form: {
        productId: null,
        tools: [],
      },

      quantityInput: [],
      quantity: [],
      quantity_select: [10, 50, 100, 200, 500, 1000, "Autres"],
    }
  },

  components: {
    Footer,
    Navbar,
  },

  methods: {
    fetchData(url) {
      this.loading = true;
      ApiService.get(url)
          .then((res) => {
            this.current_page = res.data.current_page
            this.last_page = res.data.last_page
            this.data = res.data.data
            this.loading = false;
          })
          .catch(err => console.log(err))
    },

    go_to_page(page) {
      this.fetchData(`${this.$app_url}/api/v1/agency/commercial_campaign?page=${page}`)
    },

    parseDate(date) {
      let parsedDate = moment(date).format('DD/MM/YYYY');
      return parsedDate;
    },

    showPanoramaModal() {
      this.$confirm(
          {
            message: `Voulez-vous télécharger le fichier ?`,
            button: { no: 'Non', yes: 'Oui' },
            callback: confirm => {
              if (confirm) {
                this.loading = true;
                axios({
                  url: `${this.$app_url}/api/v1/agency/commercial_campaign/file/panorama`,
                  method: "GET",
                  responseType: "blob",
                }).then((response) => {
                  const ext = response.data.type.split("/")[1];
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", `panorama.${ext}`);
                  document.body.appendChild(link);
                  link.click();
                }).catch((err) => {
                  this.$emit("flash-warning-snackbar", err.response.request.statusText);
                }).finally(() => {
                  this.loading = false;
                });
              }
            }
          }
      )
    },

    campagne_detail(campagne) {
      campagne.file = campagne.visual_key;
      delete campagne.visual_key;
      this.$router.push({
        name: 'CampagneDetails',
        params: {
          campagne: campagne,
          parent: 'Campagnes',
        }
      })

    },

    show_campagne_modal(item) {
      axios({
        url: `${this.$app_url}/api/v1/user/file/${item.visual_key.id}`,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const ext = response.data.type.split('/')[1];
        let url = '';
        if (ext === 'pdf') {
          url = window.URL.createObjectURL(new Blob([response.data],{ type: 'application/pdf' }));

        } else {
          url = window.URL.createObjectURL(new Blob([response.data]));
        }

        this.campagne_modal_info.name 	= item.name
        this.campagne_modal_info.file 	= url
        this.campagne_modal_info.ext 	= ext

        this.campagne_modal_show = true
      })
          .catch(err => this.$emit('flash-danger-snackbar', (err.response.statusText)))
    },

    show_campagne_tool_modal(item) {
      axios({
        url: `${this.$app_url}/api/v1/user/file/${item.visualKey.id}`,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const ext = response.data.type.split('/')[1];
        let url = null;

        if (ext === 'pdf') {
          url = window.URL.createObjectURL(new Blob([response.data],{ type: 'application/pdf' }));

        } else {
          url = window.URL.createObjectURL(new Blob([response.data]));
        }

        this.campagne_modal_info.name 	= item.name
        this.campagne_modal_info.file 	= url
        this.campagne_modal_info.ext 	= ext

        this.campagne_tool_modal_show = true
      })
          .catch(err => this.$emit('flash-danger-snackbar', (err.response.statusText)))
    },

    download_file() {
      this.$confirm(
          {
            message: `Voulez-vous télécharger le fichier ?`,
            button: { no: 'Non', yes: 'Oui' },
            callback: confirm => {
              if (confirm) {
                const link = document.createElement('a');
                link.href = this.campagne_modal_info.file;
                link.setAttribute('download', `${this.campagne_modal_info.name}.${this.campagne_modal_info.ext}`);
                document.body.appendChild(link);
                link.click();
              }
            }
          }
      )

    },

    download_tool_file(item) {
      this.$confirm(
          {
            message: `Voulez-vous télécharger le fichier ?`,
            button: { no: 'Non', yes: 'Oui' },
            callback: confirm => {
              if (confirm) {
                axios({
                  url: `${this.$app_url}/api/v1/user/file/${item.visualKey.id}`,
                  method: 'GET',
                  responseType: 'blob',
                }).then((response) => {
                  const ext =  response.data.type.split('/')[1];
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', `${item.visualKey.name}.${ext}`);
                  document.body.appendChild(link);
                  link.click();
                });
              }
            }
          }
      )

    },

    show_outils_modal(item) {
      this.outils_table = [];
      this.outil_order_form = { productId: null, tools: [] };
      ApiService.get(`${this.$app_url}/api/v1/agency/commercial_campaign/${item.id}/tool`)
          .then((res) => {
            res.data.data.forEach(item => {
              let obj = item;
              obj.active = false;
              this.outils_table.push(obj);
            });
            if (res.data.last_page > 1) {
              for (let i = 1 ; i < res.data.last_page ; i++) {
                ApiService.get(`${this.$app_url}/api/v1/agency/commercial_campaign/${item.id}/tool?page=${i+1}`)
                    .then((ressponse) => {
                      ressponse.data.data.forEach(item => {
                        let obj = item;
                        obj.active = false;
                        this.outils_table.push(obj);
                      });
                    })
                    .catch(() => this.$emit('flash-danger-snackbar', "Erreur"))
              }
            }
            this.outils_modal_info.campagne_id 		= item.id
            this.outils_modal_info.campagne_name 	= item.name
            this.outils_modal_info.date 			= `Du ${item.start_date} au ${item.end_date}`

            this.outil_order_form.productId = item.id

            this.outils_modal_show = true
          })
          .catch(() => this.$emit('flash-danger-snackbar', "Erreur"))
    },

    click_command_checkbox(tool) {
      let item_exists = false

      this.outil_order_form.tools.forEach(function(element, index, object) {
        if(element.id === tool.id) {
          object.splice(index, 1);
          item_exists = true;
        }
      })

      if(item_exists) {
        this.quantity[tool.id] = null;

        this.outils_table.forEach(item => {
          if (item.id === tool.id) {
            item.active = false;
          }
        });

      }

      if(!item_exists) {
        this.outils_table.forEach(item => {
          if (item.id === tool.id) {
            item.active = true;
          }
        });

        if(tool.format_tool.type !== 'Free') {
          this.outil_order_form.tools.push({'id': tool.id, 'quantity': this.quantity[tool.id]});
        }
        else {
          this.outil_order_form.tools.push({'id': tool.id, 'quantity': 1 });
        }

      }
    },

    click_quantity_checkbox(tool_id) {
      console.log(tool_id);
    },

    clear_quantity(tool_id) {
      alert(tool_id);
    },

    update_quantity(tool_id) {
      let quantity = this.quantity[tool_id];
      this.outil_order_form.tools.forEach(function(element) {
        if(element.id == tool_id) {
          element.quantity = quantity
        }
      })
    },

    update_quantity_input(tool_id) {
      let quantity = this.quantityInput[tool_id];
      if (quantity !== '') {
        this.outil_order_form.tools.forEach(function (element) {
          if (element.id == tool_id) {
            element.quantity = Number(quantity);
          }
        });
      }
      if (quantity === '') {
        this.quantity[tool_id] = null;
        this.outil_order_form.tools.forEach(item => {
          if (item.id === tool_id) {
            item.quantity = null;
          }
        })
      }
    },

    calc_quantity(tool) {
      if (this.quantity[tool.id]) {
        let price = tool.formats.filter(el => { return el.quantity === this.quantity[tool.id] })[0].price;
        return `${price} €`;
      }
      else {
        return '';
      }
    },

    place_order() {
      ApiService.post(`${this.$app_url}/api/v1/agency/order/commercial_campaign`, this.outil_order_form)
          .then(() => {
            this.outils_modal_show = false
            this.merci_modal_show = true
          })
          .catch((error) => {
            if (error.response.data.errors.tools) {
              this.$emit('flash-danger-snackbar', "Verifier les case à cocher commander");
            }
            else{
              this.$emit('flash-danger-snackbar', "Verifier les champs quantité");
            }

          });
    },
  }
}
</script>

<style lang="scss">
.v-dialog {
  .campagne-modal,
  .panorama-modal {
    img {
      width:100%;
      height:auto;
    }
  }

  .campagne-modal,
  .campagne-outils-modal{
    .modal-title {
      &:after {
        content : none;
      }

      font-size: 16px;
      text-transform: initial;
      line-height: 1em;
      font-weight: 500;
    }

    .close-modal{
      position: relative;
      left: 0;
      top: 0;
      color: #000 !important;
    }

    .outils-table {
      border-radius: 0 !important;

      thead tr{
        th {
          padding: 12px;
          text-align: center !important;
          color : #000 !important;
          border-bottom : 2px solid #000 !important;

          &:nth-child(3) {
            border-right : 2px solid #000 !important;
          }
        }
      }

      tbody tr {
        td {
          text-align: center !important;
          border : none !important;

          &:nth-child(3) {
            border-right : 2px solid #000 !important;
          }
        }
      }
    }

    .circle{
      width: 12px;
      height: 12px;
      border-radius: 50%;

      &.circle-black {
        background-color:#000;
      }

      &.circle-gold {
        background-color:#a37f43;
      }
    }
  }
}
table.fixed { table-layout:fixed; }
table.fixed td { overflow: hidden; }

.campagnes-table {
  border-radius: 0 !important;

  thead tr{
    th {
      color : #000 !important;
      border-bottom : 1px solid #000 !important;
    }
  }

  tbody tr {
    &:hover,
    &:hover td .v-btn--icon {
      background: #000 !important;
      color: #fff !important;
    }

    td {
      border : none !important;
    }

    td .v-btn--icon .v-icon{
      font-size: 18px;

      &:hover{
        font-size: 24px;
      }
    }

  }
}

.panorama-btn {
  width: 100%;
}

.actions-btn-container {
  justify-content: flex-start;
}

@media only screen and (min-width: 600px) {
  .panorama-btn {
    width: auto;
  }

  .actions-btn-container {
    justify-content: flex-end;
  }
}
</style>

