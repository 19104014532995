<template>

	<div class="login-container">
		<div class="logo"></div>

		<v-row no-gutters class="black">
			<v-col lg="6" cols="12" class="login-img"></v-col>

			<v-col lg="6" cols="12" class="form-container d-flex justify-center align-center">

				<v-container class="text-center">
					<p class="font-weight-light ma-0 text-bienvenue">Bienvenue sur la plateforme</p>

          <img src="../assets/img/mynoblessa-blanc.svg" width="50%" class="mt-4">

					<h5 class="text-subtitle-1 font-weight-regular mb-6 mt-6" style="font-family: 'Helvetica Neue'">Changement de votre mot de passe</h5>

					<v-row no-gutters>

						<v-col lg="4" md="2" sm="1" cols="12"></v-col>

						<v-col lg="4" md="8" sm="10" cols="12">
                <v-text-field
                    v-model="change_password_form.password"
                    type="password"
                    :error-messages="validation_errors.password"
                    label="Mot de passe"
                    required
                ></v-text-field>

                <v-text-field
                    v-model="change_password_form.password_confirmation"
                    type="password"
                    :error-messages="validation_errors.password_confirmation"
                    label="Confiramtion"
                    required
                ></v-text-field>

								<v-btn medium outlined text tile :loading="loading" class="my-4 font-weight-light submit-btn" color="white" @click="submit">
									Envoyer !
								</v-btn>
						</v-col>

						<v-col lg="4" md="2" sm="1" cols="12"></v-col>

					</v-row>
				</v-container>

			</v-col>
		</v-row>
	</div>

</template>

<script>

import ApiService from "@/services/api.service";

export default {
  created() {
    localStorage.clear();
  },
  data() {
		return {
			validation_errors: {
				email: null,
			},

      change_password_form: {
        token: this.$route.params.token,
        password: '',
        password_confirmation: '',
      },
      loading: false,
		}
	},

	methods: {
		submit() {
      this.loading = true;

      ApiService.post(`${this.$app_url}/api/v1/agency/reset-password`, this.change_password_form)
          .then(() => {
            this.$emit("flash-success-snackbar", "Votre mot de passe a été changé avec succès");
            this.$router.push({name: 'Login'});
          })
          .catch(({response}) => {
            if (response.status === 422) {
              this.validation_errors = response.data.errors;
            }
            else {
              this.$emit('flash-danger-snackbar', "le token n'est pas valide");
              this.$router.push({name: 'ResetPassword'});
            }

          })
          .finally(() => {
            this.loading = false;
          });

		}
	}
}
</script>
<style>
.font-helvetica {
  font-family: "Helvetica Neue";
}
</style>