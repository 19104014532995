<template>

	<div class="page-wrapper grey lighten-4">

		<v-container>
			<v-row class="py-4">
				<v-col cols="12" class="d-flex flex-column align-start justify-center">
          <v-btn @click="$router.push({ name: 'CorporateBeauVivre' })" tile depressed class="subtitle-2 text-lowercase pl-0">
            <v-icon class="font-weight-regular pr-2" color="black">mdi-arrow-left</v-icon> Retour
          </v-btn>
					<p class="text-h6 ma-0 pl-3" style="border-left:4px solid #000;">Détail Outil</p>
				</v-col>
			</v-row>

			<v-row  class="white py-4">
				<v-col v-if="outil_info.ext === 'pdf'">
          <div class="pdfobject-container">
            <iframe :src="outil_info.file" type="application/pdf" width="100%" height="400" style="overflow: auto;">
            </iframe>
          </div>
				</v-col>
        <v-col class="d-flex flex-column align-center justify-center" v-else-if="outil_info.ext === 'zip'">
          <p class="text-h6 ma-0 mb-6">Aucune visualisation possible</p>
          <span class="mb-6">
            Cliquer sur le bouton pour télécharger le fichier.
          </span>
          <v-btn
              class="ma-2 white--text"
              fab
              color="grey darken-4"
              @click="download_file(tool)"
          >
            <v-icon dark>
              mdi-download
            </v-icon>
          </v-btn>
        </v-col>
        <v-col v-else>
          <v-img :src="outil_info.file" max-height="400" max-width="400"></v-img>
				</v-col>
			</v-row>
		</v-container>

	</div>
</template>

<script>
import axios from "axios";

export default {
  created () {
    if(!this.tool) {
      this.$router.push({ name: 'CorporateBeauVivre' })
    }
  },
	mounted(){
    this.get_file(this.tool.file.id);
	},

	data() {
		return {

    outil_info: {
      name:null,
      file:null,
      ext:null,
    },

		}
	},

  props: ['tool'],

	components: {

	},

	computed: {

	},

	methods: {
    get_file(id) {
      axios({
        url: `${this.$app_url}/api/v1/user/file/${id}`,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const ext = response.data.type.split('/')[1];
        let url = '';
        if (ext === 'pdf') {
          url = window.URL.createObjectURL(new Blob([response.data],{ type: 'application/pdf' }));

        } else {
          url = window.URL.createObjectURL(new Blob([response.data]));
        }

        this.outil_info.file 	= url
        this.outil_info.ext 	= ext

      })
      .catch(err => {
        this.$emit('flash-danger-snackbar', (err.response.statusText))
      })
    },

    download_file(file) {
      axios({
        url: `${this.$app_url}/api/v1/user/file/${file.file.id}`,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const ext =  response.data.type.split('/')[1];
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${file.file.name}.${ext}`);
        document.body.appendChild(link);
        link.click();
      });
    },

	}
}
</script>

<style lang="scss">
.outil-form .v-input .v-label{
    font-weight: bold !important;
    color: #000 !important;
}

.circle{
	width: 12px;
	height: 12px;
	border-radius: 50%;
	border:1px solid #000;

	&.circle-black {
		background-color:#000;
		border: none;
	}

	&.circle-gold {
		background-color:#a37f43;
		border: none;
	}
}
</style>