<template>
	<div class="white">
		<v-container>
			<v-row no-gutters>
				<v-app-bar color="white" flat height="100">
					<v-app-bar-nav-icon v-if="$route.name !== 'Menu'" color="black" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

					<v-spacer></v-spacer>

          <router-link to="/">
            <img src="~@/assets/img/logo.png" alt="Mynoblessa">
          </router-link>


					<v-spacer></v-spacer>

          <v-menu
              rounded="Removed"
              offset-y
          >
          <template v-slot:activator="{ attrs, on }">
              <v-btn class="align-center d-flex justify-end pa-0" v-bind="attrs" v-on="on" depressed tile color="white">
                <v-icon>mdi-account-outline</v-icon>
                <span class="d-md-flex d-none">
                {{ get_profile ? get_profile : '' }}
              </span>
              </v-btn>
            </template>
            <v-list class="pt-4 pl-0 pr-0 pb-0 grey lighten-4">
              <v-list-item link @click="go_to_profile">
                <v-list-item-title>Mon profil</v-list-item-title>
              </v-list-item>
            </v-list>

            <v-list class="pt-4 pl-0 pr-0 pb-0 grey lighten-4">
              <v-list-item link @click="deconnexion">
                <v-list-item-title>Déconnexion</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

				</v-app-bar>
			</v-row>
		</v-container>

		<v-navigation-drawer v-model="drawer" absolute bottom temporary width="400">
			<v-row class="py-2 px-4">
				<v-col class="nav-menu menu-container" cols="12">
					<v-btn to="/campagnes" tile plain class="menu-btn menu-campagnes">
						<p class="menu-item-text">Mes campagnes commerciales</p>
						<div class="menu-item-img"></div>
					</v-btn>
				</v-col>

				<v-col class="nav-menu menu-container" cols="12">
					<v-btn to="/point-vente" tile plain class="menu-btn menu-point-vente">
						<p class="menu-item-text">Mon point de vente</p>
						<div class="menu-item-img"></div>
					</v-btn>
				</v-col>

				<v-col class="nav-menu menu-container" cols="12">
					<v-btn to="/activation" tile plain class="menu-btn menu-activation">
						<p class="menu-item-text">Mon activation</p>
						<div class="menu-item-img"></div>
					</v-btn>
				</v-col>

				<v-col class="nav-menu menu-container" cols="12">
					<v-btn to="/reseaux-sociaux" tile plain class="menu-btn menu-reseaux-sociaux">
						<p class="menu-item-text">Mes réseaux sociaux</p>
						<div class="menu-item-img"></div>
					</v-btn>
				</v-col>

				<v-col class="nav-menu menu-container" cols="12">
					<v-btn to="/corporate-beau-vivre" tile plain class="menu-btn menu-corporate-beau-vivre">
						<p class="menu-item-text">Corporate Le Beau Vivre</p>
						<div class="menu-item-img"></div>
					</v-btn>
				</v-col>

				<v-col class="nav-menu menu-container" cols="6">
					<v-btn to="/produits" tile plain class="menu-btn menu-produits">
						<p class="menu-item-text">Mes Produits</p>
						<div class="menu-item-img"></div>
					</v-btn>
				</v-col>

				<v-col class="nav-menu menu-container" cols="6">
					<v-btn to="/kit-ouverture" tile plain class="menu-btn menu-kit-ouverture font-weight-regular">
						<p class="menu-item-text">Mon kit d'ouverture</p>
						<div class="menu-item-img"></div>
					</v-btn>
				</v-col>
			</v-row>


		</v-navigation-drawer>
	</div>
</template>

<script>
export default {
	computed: {
		get_profile() {
			if (localStorage.getItem('set_profile')) {
				return localStorage.getItem('set_profile')
			}
			else {
				return null
			}
		}
	},

	data() {
		return {
			drawer: false,
			group: null,
		}
	},

	watch: {
		group () {
			this.drawer = false
		},
	},

  methods: {
    go_to_profile() {
      this.$router.push({ path: '/profil'})
    },
    deconnexion() {
      localStorage.clear();
      this.$router.push({  name: 'Login' });
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/styles/navigation.scss";

.v-toolbar .v-toolbar__content{
	padding: 0;
}
</style>