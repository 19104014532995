var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"footer-container"},[_c('v-row',{staticClass:"white py-4 px-2"},[_c('v-col',{staticClass:"pa-0 text-center",attrs:{"sm":"4","cols":"4"}},[_c('v-dialog',{attrs:{"width":"550"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"footer-btn pa-0 font-weight-bold",attrs:{"tile":"","depressed":"","color":"white"}},'v-btn',attrs,false),on),[_vm._v(" Envoyer un E-mail ")])]}}]),model:{value:(_vm.email_modal),callback:function ($$v) {_vm.email_modal=$$v},expression:"email_modal"}},[_c('v-card',[_c('v-card-title',{staticClass:"modal-title headline font-weight-bold"},[_vm._v(" Envoyer un e-mail "),_c('v-btn',{staticClass:"close-modal",attrs:{"icon":""},on:{"click":function($event){_vm.email_modal = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"px-12"},[_c('v-container',_vm._l((_vm.contacts),function(contact){return _c('v-row',{key:contact.id,staticClass:"py-5",staticStyle:{"border-bottom":"1px solid #000"}},[_c('v-col',{attrs:{"sm":"6","cols":"12"}},[_c('p',{staticClass:"subtitle-1 ma-0 font-weight-bold black--text"},[_vm._v(_vm._s(contact.first_name+' '+contact.last_name))]),_c('p',{staticClass:"subtitle-2 ma-0 font-weight-medium black--text"},[_vm._v(_vm._s(contact.function))])]),_c('v-col',{staticClass:"d-flex align-end justify-sm-end justify-start",attrs:{"sm":"6","cols":"12"}},[_c('v-icon',{staticClass:"mr-2 black--text"},[_vm._v("mdi-email")]),_c('span',{staticClass:"font-weight-medium black--text"},[_c('A',{staticClass:"black--text",attrs:{"href":("mailto:" + (contact.email))}},[_vm._v("Envoyer un e-mail")])],1)],1)],1)}),1)],1)],1)],1)],1),_c('v-col',{staticClass:"pa-0  text-center",attrs:{"sm":"4","cols":"4"}},[_c('v-dialog',{attrs:{"width":"550"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"footer-btn pa-0 font-weight-bold",attrs:{"tile":"","depressed":"","color":"white"}},'v-btn',attrs,false),on),[_vm._v(" Appeler ")])]}}]),model:{value:(_vm.phone_modal),callback:function ($$v) {_vm.phone_modal=$$v},expression:"phone_modal"}},[_c('v-card',[_c('v-card-title',{staticClass:"modal-title headline font-weight-bold"},[_vm._v(" Appeler "),_c('v-btn',{staticClass:"close-modal",attrs:{"icon":""},on:{"click":function($event){_vm.phone_modal = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"px-12"},[_c('v-container',_vm._l((_vm.contacts),function(contact){return _c('v-row',{key:contact.id,staticClass:"py-5",staticStyle:{"border-bottom":"1px solid #000"}},[_c('v-col',{attrs:{"sm":"6","cols":"12"}},[_c('p',{staticClass:"subtitle-1 ma-0 font-weight-bold black--text"},[_vm._v(_vm._s(contact.first_name+' '+contact.last_name))]),_c('p',{staticClass:"subtitle-2 ma-0 font-weight-medium black--text"},[_vm._v(_vm._s(contact.function))])]),_c('v-col',{staticClass:"d-flex flex-column align-center justify-sm-end justify-start",attrs:{"sm":"6","cols":"12"}},[_c('p',{staticClass:"subtitle-2 ma-0 font-weight-medium black--text"},[_vm._v(_vm._s(contact.phone))]),_c('v-icon',{staticClass:"mr-2 black--text"},[_vm._v("mdi-phone")]),_c('span',{staticClass:"font-weight-medium black--text"},[_vm._v("Appeler")])],1)],1)}),1)],1)],1)],1)],1),_c('v-col',{staticClass:"pa-0  text-center",attrs:{"sm":"4","cols":"4"}},[_c('v-dialog',{staticClass:"footer-dialog",attrs:{"width":"750"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"footer-btn pa-0 font-weight-bold",attrs:{"tile":"","depressed":"","color":"white"}},'v-btn',attrs,false),on),[_vm._v(" Production CGV ")])]}}]),model:{value:(_vm.cgv_modal),callback:function ($$v) {_vm.cgv_modal=$$v},expression:"cgv_modal"}},[_c('v-card',[_c('v-card-title',{staticClass:"modal-title headline font-weight-bold"},[_vm._v(" Production CGV "),_c('v-btn',{staticClass:"close-modal",attrs:{"icon":""},on:{"click":function($event){_vm.cgv_modal = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"py-4 px-sm-16 px-6"},[_c('CFGContent')],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }