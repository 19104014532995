<template>

	<div class="login-container">
		<div class="logo"></div>

		<v-row no-gutters class="black">
			<v-col lg="6" cols="12" class="login-img"></v-col>

			<v-col lg="6" cols="12" class="form-container d-flex justify-center align-center">

				<v-container class="text-center">
					<p class="font-weight-light ma-0 text-bienvenue">Bienvenue sur la plateforme</p>

          <img src="../assets/img/mynoblessa-blanc.svg" width="50%" class="mt-4">

					<h5 class="text-subtitle-1 font-weight-regular mb-6 mt-6" style="font-family: 'Helvetica Neue'">Réinitialiser votre mot de passe</h5>

					<v-row no-gutters>

						<v-col lg="4" md="2" sm="1" cols="12"></v-col>

						<v-col lg="4" md="8" sm="10" cols="12">
							<form @submit.prevent="submit">
								<v-text-field
									v-model="email"
									label="Email"
									:error-messages="validation_errors.email"
									required
								></v-text-field>

								<v-btn medium outlined text tile :loading="loading" class="my-4 font-weight-light submit-btn" color="white" @click="submit">
									Envoyer !
								</v-btn>
							</form>
						</v-col>

						<v-col lg="4" md="2" sm="1" cols="12"></v-col>

					</v-row>
				</v-container>

			</v-col>
		</v-row>
	</div>

</template>

<script>

import ApiService from "@/services/api.service";

export default {
  created() {
    localStorage.clear();
  },
  data() {
		return {
			validation_errors: {
				email: null,
			},

      email: '',
      loading: false,
		}
	},

	methods: {
		submit() {
      this.loading = true;

      ApiService.post(`${this.$app_url}/api/v1/agency/forgot-password`, { email: this.email })
          .then(() => {
            this.loading = false;
            this.$emit("flash-success-snackbar", "Vérifier votre boîte mail.");
            this.validation_errors = {};
          })
          .catch(({response}) => {
            this.loading = false;
            this.validation_errors = response.data.errors;
          });

		}
	}
}
</script>
<style>
.font-helvetica {
  font-family: "Helvetica Neue";
}
</style>