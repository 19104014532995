<template>

	<div class="page-wrapper grey lighten-4">
		<Navbar/>

		<v-container >
			<v-row class="py-4">
				<v-col class="d-flex flex-column align-start justify-center">
					<v-btn to="/" tile depressed class="subtitle-2 text-lowercase pl-0">
						<v-icon class="font-weight-regular pr-2" color="black">mdi-arrow-left</v-icon> Retour
					</v-btn>
					<p class="text-h6 ma-0 pl-3" style="border-left:4px solid #000;">Mes réseaux sociaux</p>
				</v-col>
			</v-row>

			<v-row class="white py-4 pb-12">
        <v-col lg="2" md="3" sm="4" cols="12" class="reseaux-sociaux-container">
          <v-btn to="/reseaux-sociaux/01" tile plain class="reseaux-sociaux-btn janvier">
            <p class="reseaux-sociaux-btn-text">Janvier</p>
          </v-btn>
        </v-col>

        <v-col lg="2" md="3" sm="4" cols="12" class="reseaux-sociaux-container">
          <v-btn to="/reseaux-sociaux/02" tile plain class="reseaux-sociaux-btn fevrier">
            <p class="reseaux-sociaux-btn-text">Février</p>
          </v-btn>
        </v-col>

        <v-col lg="2" md="3" sm="4" cols="12" class="reseaux-sociaux-container">
          <v-btn to="/reseaux-sociaux/03" tile plain class="reseaux-sociaux-btn mars">
            <p class="reseaux-sociaux-btn-text">Mars</p>
          </v-btn>
        </v-col>

        <v-col lg="2" md="3" sm="4" cols="12" class="reseaux-sociaux-container">
          <v-btn to="/reseaux-sociaux/04" tile plain class="reseaux-sociaux-btn avril">
            <p class="reseaux-sociaux-btn-text">Avril</p>
          </v-btn>
        </v-col>

        <v-col lg="2" md="3" sm="4" cols="12" class="reseaux-sociaux-container">
          <v-btn to="/reseaux-sociaux/05" tile plain class="reseaux-sociaux-btn mai">
            <p class="reseaux-sociaux-btn-text">Mai</p>
          </v-btn>
        </v-col>

        <v-col lg="2" md="3" sm="4" cols="12" class="reseaux-sociaux-container">
          <v-btn to="/reseaux-sociaux/06" tile plain class="reseaux-sociaux-btn juin">
            <p class="reseaux-sociaux-btn-text">Juin</p>
          </v-btn>
        </v-col>

        <v-col lg="2" md="3" sm="4" cols="12" class="reseaux-sociaux-container">
          <v-btn to="/reseaux-sociaux/07" tile plain class="reseaux-sociaux-btn juillet">
            <p class="reseaux-sociaux-btn-text">Juillet</p>
          </v-btn>
        </v-col>

        <v-col lg="2" md="3" sm="4" cols="12" class="reseaux-sociaux-container">
          <v-btn to="/reseaux-sociaux/08" tile plain class="reseaux-sociaux-btn aout">
            <p class="reseaux-sociaux-btn-text">Août</p>
          </v-btn>
        </v-col>

        <v-col lg="2" md="3" sm="4" cols="12" class="reseaux-sociaux-container">
          <v-btn to="/reseaux-sociaux/09" tile plain class="reseaux-sociaux-btn septembre">
            <p class="reseaux-sociaux-btn-text">Septembre</p>
          </v-btn>
        </v-col>

        <v-col lg="2" md="3" sm="4" cols="12" class="reseaux-sociaux-container">
          <v-btn to="/reseaux-sociaux/10" tile plain class="reseaux-sociaux-btn octobre">
            <p class="reseaux-sociaux-btn-text">Octobre</p>
          </v-btn>
        </v-col>

        <v-col lg="2" md="3" sm="4" cols="12" class="reseaux-sociaux-container">
          <v-btn to="/reseaux-sociaux/11" tile plain class="reseaux-sociaux-btn novembre">
            <p class="reseaux-sociaux-btn-text">Novembre</p>
          </v-btn>
        </v-col>

				<v-col lg="2" md="3" sm="4" cols="12" class="reseaux-sociaux-container">
					<v-btn to="/reseaux-sociaux/12" tile plain class="reseaux-sociaux-btn decembre">
						<p class="reseaux-sociaux-btn-text">Décembre</p>
					</v-btn>
				</v-col>

			</v-row>

		</v-container>

		<Footer/>
	</div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import Navbar from '@/components/Navbar.vue'

export default {

	data() {
		return {

		}
	},

	components: {
		Footer,
		Navbar,
	},

	computed: {

	},

	methods: {

	}
}
</script>

<style lang="scss">
.reseaux-sociaux-container {
	.reseaux-sociaux-btn{
		width: 100%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		min-height: 200px;
		font-size: 22px;

		.v-btn__content{
			display: block;
			z-index:1;
			opacity: 1 !important;

			.reseaux-sociaux-btn-text {
				color: #fff;
				opacity: 1 !important;
				z-index: 1;
				display: block;
				text-align: center;
				width: 100%;
				font-weight: 600;
			}
		}

		&:after {
			content: "";
			background: rgb(0 0 0 / 30%);
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
		}

		&:hover:after {
			background: rgb(0 0 0 / 60%);
		}

		&.decembre { background-image : url('~@/assets/img/reseaux_sociaux/decembre.png'); }

		&.aout { background-image : url('~@/assets/img/reseaux_sociaux/aout.png') ;}

		&.avril { background-image : url('~@/assets/img/reseaux_sociaux/avril.png'); }

		&.fevrier { background-image : url('~@/assets/img/reseaux_sociaux/fevrier.png'); }

		&.janvier { background-image : url('~@/assets/img/reseaux_sociaux/janvier.png'); }

		&.juillet { background-image : url('~@/assets/img/reseaux_sociaux/juillet.png'); }

		&.juin { background-image : url('~@/assets/img/reseaux_sociaux/juin.png'); }

		&.mai { background-image : url('~@/assets/img/reseaux_sociaux/mai.png'); }

		&.mars { background-image : url('~@/assets/img/reseaux_sociaux/mars.png'); }

		&.novembre { background-image : url('~@/assets/img/reseaux_sociaux/novembre.png'); }

		&.octobre { background-image : url('~@/assets/img/reseaux_sociaux/octobre.png'); }

		&.septembre { background-image : url('~@/assets/img/reseaux_sociaux/septembre.png'); }
	}
}
</style>