<template>
	<div class="page-wrapper grey lighten-4">
		<Navbar/>

		<v-container>
			<v-row class="py-4">
				<v-col cols="12" class="d-flex flex-column align-start justify-center">
					<v-btn to="/" tile depressed class="subtitle-2 text-lowercase pl-0">
						<v-icon class="font-weight-regular pr-2" color="black">mdi-arrow-left</v-icon> Retour
					</v-btn>
					<p class="text-h6 ma-0 pl-3" style="border-left:4px solid #000;">Mon point de vente</p>
				</v-col>
			</v-row>

			<v-row class="white py-4 pb-12 elevation-1">
				<v-col>
					<v-data-table class="point-vente-table" :headers="pdv_headers" :items="pdv_data" :disable-sort="true" :hide-default-footer="true" :loading="loading" loading-text="Loading... Please wait">
						<template v-slot:item="row">
							<tr style="cursor: pointer" @click="show_pdv_modal(row.item)">
								<td>
									{{row.item.type}}
								</td>
								<td>
									{{row.item.tools_count}}
								</td>
							</tr>
						</template>
					</v-data-table>

					<v-col>
						<template>
						<div class="text-right">
							<v-pagination
								color="grey"
								v-model="current_page"
								:length="last_page"
								@input="go_to_page"
								prev-icon="mdi-menu-left"
								next-icon="mdi-menu-right"
							></v-pagination>
						</div>
						</template>
					</v-col>
				</v-col>

        <v-dialog v-model="pdv_tool_modal_show" width="700">
          <v-card class="campagne-modal">
            <v-card-title class="modal-title">
              <v-container class="pa-0">
                <v-row no-gutters>
                  <v-col cols=1 class="d-flex align-center justify-start">
                    <v-btn icon class="close-modal" @click="pdv_tool_modal_show = false; this.downloaded_item = {}">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>

            <v-card-text>
              <v-container class="ma-0 pa-0">
                <v-row  class="white py-4">
                  <v-col v-if="pdv_modal_info.ext === 'pdf'">
                    <div class="pdfobject-container">
                      <iframe :src="pdv_modal_info.file" type="application/pdf" width="100%" height="400" style="overflow: auto;">
                      </iframe>
                    </div>
                  </v-col>
                  <v-col class="d-flex flex-column align-center justify-center" v-else-if="pdv_modal_info.ext === 'zip'">
                    <p class="text-h6 ma-0 mb-6">Aucune visualisation possible</p>
                    <span class="mb-6">
                      Cliquer sur le bouton pour télécharger le fichier.
                    </span>
                    <v-btn
                        class="ma-2 white--text"
                        fab
                        color="grey darken-4"
                        @click="download_file"
                    >
                      <v-icon dark>
                        mdi-download
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col v-else>
                    <img :src="pdv_modal_info.file" alt="">
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>

				<v-dialog v-model="pdv_modal_show" width="1200">
					<v-card class="point-vente-modal">
						<v-card-title class="modal-title ">
							<v-container class="pa-0">
								<v-row no-gutters>
									<v-col cols=1 class="d-flex align-center justify-start">
										<v-btn icon class="close-modal" @click="pdv_modal_show = false">
											<v-icon>mdi-close</v-icon>
										</v-btn>
									</v-col>

									<v-col cols=11 class="d-flex align-center justify-sm-start justify-center">
										{{ selected_item ? selected_item.type :  "" }}
									</v-col>
								</v-row>
							</v-container>
						</v-card-title>

						<v-card-text class="grey lighten-2 py-0">
							<v-container>
								<v-row class="white my-2">
									<v-col cols=12>
										<v-simple-table dense fixed-header class="pdv-modal-table">
											<template v-slot:default>
												<thead>
													<tr>
														<th>Outils</th>
														<th>Afficher</th>
														<th>Commander</th>
														<th class="text-center">Quantité</th>
														<th>Prix</th>
													</tr>
												</thead>

												<tbody>
													<tr v-for="(outil, index) in pdv_modal_data" :key="outil.id">
														<td style="width: 30%" class="font-weight-medium">{{ outil.name }}</td>
														<td style="width: 15%">
															<v-btn icon small color="black">
																<v-icon @click="show_pdv_tool_modal(outil)" dark>mdi-eye</v-icon>
															</v-btn>
														</td>
														<td style="width: 15%">
															<v-btn icon small color="black">
																<v-checkbox @click="click_command_checkbox(outil.id)" color="black"></v-checkbox>
															</v-btn>
														</td>
                            <td style="width: 20%">
                              <v-select :disabled="!outil.active"  v-if="quantity[outil.id] !== 'Autres'" @change="update_quantity(outil.id)" v-model="quantity[outil.id]" :error-messages="validation_errors[`tools.${index}.quantity`]" dense class="mx-auto pa-0" style="width:100px;" height="25" color="black" :items="quantity_select"></v-select>
                              <v-text-field :disabled="!outil.active" class="mx-auto pa-0 ma-0" style="width:100px;" v-if="quantity[outil.id] === 'Autres'" v-model="quantityInput[outil.id]" @input="update_quantity_input(outil.id)"></v-text-field>
                            </td>
                            <td style="width: 10%"  class="font-weight-medium">{{calc_quantity(outil)   }}</td>
													</tr>
												</tbody>
											</template>
										</v-simple-table>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>

						<v-card-actions class="grey lighten-2">
							<v-container>
								<v-row>
									<v-col cols=12 class="d-flex justify-center align-center">
										<v-btn @click="place_order()" class="black-btn" tile outlined depressed>
											Confirmer
										</v-btn>
									</v-col>
								</v-row>
							</v-container>
						</v-card-actions>
					</v-card>
				</v-dialog>


				<v-dialog v-model="merci_modal_show" width="500">
					<v-card class="merci-modal">
						<v-card-text>
							<v-container class="d-flex flex-column justify-center align-center">
								<v-icon class="font-weight-medium black--text text-h1" color="black">mdi-check-circle-outline</v-icon>
								<p class="font-weight-medium black--text text-h6">Merci!</p>
								<p class="font-weight-medium black--text text-subtitle-1">Votre commande a bien été prise en compte</p>
							</v-container>
						</v-card-text>

						<v-card-actions>
							<v-container>
								<v-row>
									<v-col cols=12 class="d-flex justify-center align-center">
										<v-btn class="black-btn" @click="merci_modal_show=false" tile outlined depressed>
											FERMER
										</v-btn>
									</v-col>
								</v-row>
							</v-container>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-row>

		</v-container>

		<Footer/>
	</div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import Navbar from '@/components/Navbar.vue'
import ApiService from '@/services/api.service';
import axios from "axios";

export default {
	mounted(){
		this.fetchData(`${this.$app_url}/api/v1/agency/point_of_sale`);
	},

	data() {
		return {
			pdv_modal_show:false,
			merci_modal_show:false,
      pdv_tool_modal_show: false,

      pdv_modal_info: {
        name:null,
        file:null,
        ext:null,
      },

			current_page: 1,
			last_page: 1,
			loading: false,

      downloaded_item: {},

			pdv_headers: [
				{ text: 'Support', value: 'type', class:"black--text"},
				{ text: 'Outils', value: 'tools_count', class:"black--text"},
			],

			pdv_data: [],
			pdv_modal_data: [],

			pdv_outil_order_form: {
				productId: null,
				tools: [],
			},

      validation_errors: {},

			quantity: [],
      quantityInput: [],

			quantity_select: [10, 50, 100, 200, 500, 1000, 'Autres'],

			selected_item: null
		}
	},

	components: {
		Footer,
		Navbar,
	},

	methods: {
		fetchData(url) {
			this.loading = true;
			ApiService.get(url)
				.then((res) => {
					this.current_page = res.data.current_page
					this.last_page = res.data.last_page
					this.pdv_data = res.data.data
					this.loading = false;
				})
				.catch(err => console.log(err))
		},

		go_to_page(page) {
			this.fetchData(`${this.$app_url}/api/v1/agency/point_of_sale?page=${page}`)
		},

		show_pdv_modal(item) {
			if(item.tools_count > 0 ){

				this.selected_item = item;
				ApiService.get(`${this.$app_url}/api/v1/agency/point_of_sale/${item.id}/tool`)
					.then((res) => {
            this.pdv_modal_data = [];
            res.data.data.forEach(item => {
              let obj = item;
              obj.active = false;
              this.pdv_modal_data.push(obj);
            });
						this.pdv_modal_show = true
	
						this.pdv_outil_order_form.productId = item.id
					})
					.catch(err => console.log(err))
			}
		},

    show_pdv_tool_modal(item) {
      axios({
        url: `${this.$app_url}/api/v1/user/file/${item.file.id}`,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const ext = response.data.type.split('/')[1];
        let url = null;

        if (ext === 'pdf') {
          url = window.URL.createObjectURL(new Blob([response.data],{ type: 'application/pdf' }));

        } else {
          url = window.URL.createObjectURL(new Blob([response.data]));
        }

        this.downloaded_item = item;

        this.pdv_modal_info.name 	= item.name
        this.pdv_modal_info.file 	= url
        this.pdv_modal_info.ext 	= ext

        this.pdv_tool_modal_show = true
      })
          .catch(err => this.$emit('flash-danger-snackbar', (err.response.statusText)))
    },

    download_file() {
      axios({
        url: `${this.$app_url}/api/v1/user/file/${this.downloaded_item.file.id}`,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const ext =  response.data.type.split('/')[1];
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${this.downloaded_item.file.name}.${ext}`);
        document.body.appendChild(link);
        link.click();
      });
    },


		click_command_checkbox(tool_id) {
			let item_exists = false

			this.pdv_outil_order_form.tools.forEach(function(element, index, object) {
				if(element.id == tool_id) {
					object.splice(index, 1);
					item_exists = true;
				}
			})

      if(item_exists) {

        this.quantity_select = [10, 50, 100, 200, 500, 1000, "Autres"];
        this.quantity[tool_id] = null;
        this.quantityInput[tool_id] = null;

        this.pdv_modal_data.forEach(item => {
          if (item.id === tool_id) {
            item.active = false;
          }
        });

      }

			if(!item_exists) {
        this.pdv_modal_data.forEach(item => {
          if (item.id === tool_id) {
            item.active = true;
          }
        });

				this.pdv_outil_order_form.tools.push({'id': tool_id, 'quantity': this.quantity[tool_id]});
			}
		},

    update_quantity(tool_id) {
      let quantity = this.quantity[tool_id];
      if (quantity !== 'Autres') {
        this.quantityInput[tool_id] = null;
        this.pdv_outil_order_form.tools.forEach(function(element) {
          if(element.id == tool_id) {
            element.quantity = quantity
          }
        })
      }
    },

    update_quantity_input(tool_id) {
      let quantity = this.quantityInput[tool_id];
      if (quantity !== '') {
        this.pdv_outil_order_form.tools.forEach(function (element) {
          if (element.id == tool_id) {
            element.quantity = Number(quantity);
          }
        });
      }
      if (quantity === '') {
        this.quantity[tool_id] = null;
        this.outil_order_form.tools.forEach(item => {
          if (item.id === tool_id) {
            item.quantity = null;
          }
        })
      }
    },

    calc_quantity(tool) {
      if (this.quantity[tool.id] && this.quantity[tool.id] !== 'Autres') {
        return `${tool.price * this.quantity[tool.id]} €`;
      } else if(this.quantityInput[tool.id] && this.quantityInput[tool.id] !== '') {
        return `${tool.price * this.quantityInput[tool.id]} €`;
      }
      else {
        return '';
      }
    },

		place_order() {
			ApiService.post(`${this.$app_url}/api/v1/agency/order/point_of_sale`, this.pdv_outil_order_form)
			.then(() => {
				this.pdv_modal_show = false
				this.merci_modal_show = true
			})
      .catch(err => {
        if (err.response.data.errors.tools) {
          this.$emit('flash-danger-snackbar', "Verifier les case à cocher commander");
        }
        else{
          this.$emit('flash-danger-snackbar', "Verifier les champs quantité");
        }
        this.validation_errors = err.response.data.errors
      })
		},
	}
}
</script>

<style lang="scss">
.v-dialog {
	.point-vente-modal{
		.modal-title {
			&:after {
				content : none;
			}

			font-size: 16px;
			text-transform: initial;
			line-height: 1em;
			font-weight: 500;
		}

		.close-modal{
			position: relative;
			left: 0;
			top: 0;
			color: #000 !important;
		}
	}
}

.point-vente-table {
	tbody tr {
		&:hover,
		&:hover td .v-btn--icon {
			background: #000 !important;
			color: #fff !important;
		}

		td .v-btn--icon .v-icon{
			font-size: 18px;

			&:hover{
				font-size: 24px;
			}
		}

	}
}

.point-vente-table,
.pdv-modal-table {
	border-radius: 0 !important;

	thead tr{
		th {
			color : #000 !important;
			border-bottom : 1px solid #000 !important;
		}
	}

	tbody tr {
		td {
			border : none !important;
		}
	}
}

.border-error-quantity {
  border-: red;
}
</style>