<template>

	<div class="page-wrapper grey lighten-4">
		<Navbar/>

		<v-container>
			<v-row class="py-4">
				<v-col md="6" cols="12" class="d-flex flex-column align-start justify-center">
					<v-btn to="/" tile depressed class="subtitle-2 text-lowercase pl-0">
						<v-icon class="font-weight-regular pr-2" color="black">mdi-arrow-left</v-icon> Retour
					</v-btn>
					<p class="text-h6 ma-0 pl-3" style="border-left:4px solid #000;">Mon activation</p>
				</v-col>

				<v-col md="6"  cols="12" class="d-flex flex-column align-start justify-end">
					<p class="text-h6 ma-0 pl-3"></p>
				</v-col>
			</v-row>

			<v-row  class="white py-4 pb-12">
				<v-col>
					<v-data-table :headers="activation_headers" :items="activation_data" class="elevation-1" :disable-sort="true" :hide-default-footer="true" :loading="loading" loading-text="Loading... Please wait">
						<template v-slot:item="row">
							<tr>
								<td>{{row.item.name}}</td>
								<td>{{row.item.description}}</td>
								<td>
									<v-btn @click="commad_modal(row.item)" icon small color="black">
										<v-icon dark>mdi-shopping-outline</v-icon>
									</v-btn>
								</td>
							</tr>
						</template>
					</v-data-table>

					<v-col>
						<template>
						<div class="text-right">
							<v-pagination
								color="grey"
								v-model="current_page"
								:length="last_page"
								@input="go_to_page"
								prev-icon="mdi-menu-left"
								next-icon="mdi-menu-right"
							></v-pagination>
						</div>
						</template>
					</v-col>
				</v-col>
			</v-row>


			<v-dialog v-model="boost_order_modal_show" width="600" persistent>
				<v-card class="activation-modal">
					<v-card-title class="modal-title headline font-weight-bold">
						Boost {{boost_order.name}}

						<v-btn icon class="close-modal" @click="devis_cancel">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-card-title>

					<v-card-text>
						<v-container class="ma-0 pa-0">
							<v-row>
								<v-col class="pb-0 mb-0">
                  <v-textarea
                      class="pb-0 mb-0"
                      filled
                      label="Commentaire"
                      v-model="boost_order.description"
                  >
                  </v-textarea>
                </v-col>
                <v-col cols="12" class="py-0 mt-0 mb-4">
                  <div v-if="validation_description" class="file-name error--text v-messages white">Le champ description n'est pas valide.</div>
                </v-col>
							</v-row>

							<v-row v-if="boost_order.name === 'À la carte'">
								<v-col md="3" cols="12">
									<v-checkbox class="ma-0" v-model="boost_order.is_sms" label="SMS" hide-details ></v-checkbox>
								</v-col>

								<v-col md="3" cols="12">
									<v-select :disabled="!boost_order.is_sms" v-model="boost_order.smsQuantity" label="Quantité" dense style="width:100px;" height="25" color="black" :items="quantity_select"></v-select>
								</v-col>

								<v-col md="3" cols="12">
									<v-checkbox class="ma-0" v-model="boost_order.is_email" label="MAIL" hide-details ></v-checkbox>
								</v-col>

								<v-col md="3" cols="12">
									<v-select :disabled="!boost_order.is_email" v-model="boost_order.mailQuantity" label="Quantité" dense style="width:100px;" height="25" color="black" :items="quantity_select"></v-select>
								</v-col>
							</v-row>
              <v-row v-if="boost_order.name === 'À la carte'" class="mt-0 pt-0">
                <v-col cols="12" class="py-0">
                  <div v-if="validation_quantity" class="file-name error--text v-messages white">Choisi au moins un type</div>
                </v-col>
              </v-row>
						</v-container>
					</v-card-text>

					<v-card-actions>
						<v-container>
							<v-row>
								<v-col cols=12 class="d-flex justify-center align-center">
									<v-btn @click="confirm_order()" class="black-btn" tile outlined depressed>
										Confirmer
									</v-btn>
								</v-col>
							</v-row>
						</v-container>
					</v-card-actions>
				</v-card>
			</v-dialog>

      <v-dialog v-model="map_modal_show" width="900" persistent>
        <v-card class="activation-modal">
          <v-card-title class="modal-title headline font-weight-bold">
            MA ZONE DE CHALANDISE

            <v-btn icon class="close-modal" @click="devis_cancel">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-container class="ma-0 pa-4">
              <v-row>
                <v-col md="6" cols="12">
                  <GmapMap
                      ref="mapRef"
                      :center="{lat:48.856614, lng:2.3522219}"
                      :zoom="10"
                      map-type-id="terrain"
                      style="width: 100%; height: 100%"
                  >
                  </GmapMap>
                </v-col>
                <v-col md="6" cols="12">
                  <v-row>
                    <v-col cols="12" class="py-2">
                      <span class="black--text font-weight-bold">Contact disponible en prospection :</span>
                      <span class="black--text font-weight-bold pl-4">{{audience_count}}</span>
                    </v-col>
                    <v-col cols="12">
                      <div v-if="validation_errors.populationCount" class="file-name error--text v-messages white">{{validation_errors.populationCount[0]}}</div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="4" cols="12" class="ma-0 pt-3 pb-0 pl-3 pr-3  d-flex align-center">
                      <span class="black--text font-weight-bold">Code postal :</span>
                    </v-col>
                    <v-col md="8" cols="12" class="ma-0 pt-3 pb-0 pl-3 pr-3  d-flex align-center">
                      <v-text-field v-model="boost_order.zipCode" :error-messages="validation_errors.zipCode" class="pa-0 ma-0"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="4" cols="12" class="ma-0 pt-0 pb-0 pl-3 pr-3 d-flex align-center">
                      <span class="black--text font-weight-bold">Distance :</span>
                    </v-col>
                    <v-col md="8" cols="12" class="ma-0 pt-0 pb-0 pl-3 pr-3 d-flex align-center">
                      <v-text-field v-model="boost_order.distance" :error-messages="validation_errors.distance" suffix="Km" class="pa-0 ma-0"></v-text-field>
                    </v-col>
                  </v-row>
<!--                  <v-row>-->
<!--                    <v-col md="4" cols="12" class="ma-0 pt-0 pb-0 pl-3 pr-3 py-2 d-flex align-center">-->
<!--                      <span class="black&#45;&#45;text font-weight-bold">Centre d'intérêt :</span>-->
<!--                    </v-col>-->
<!--                    <v-col md="8" cols="12" class="ma-0 pt-0 pb-0 pl-3 pr-3 py-2 d-flex align-center">-->
<!--                      <v-select-->
<!--                          :error-messages="validation_errors.centerOfInterestId"-->
<!--                          v-model="boost_order.centerOfInterestId"-->
<!--                          :items="interet_items"-->
<!--                          item-text="name"-->
<!--                          item-value="id"-->
<!--                          single-line-->
<!--                      ></v-select>-->
<!--                    </v-col>-->
<!--                  </v-row>-->
                  <v-row>
                    <v-col md="2" cols="12" class="ma-0 pt-0 pb-0 pl-3 pr-3 d-flex align-center">
                      <span class="black--text font-weight-bold">Genre :</span>
                    </v-col>
                    <v-col md="10" cols="12" class="ma-0 pt-0 pb-0 pl-3 pr-3 d-flex align-center">
                      <v-select
                          :error-messages="validation_errors.gender"
                          v-model="boost_order.gender"
                          :items="gender_items"
                          item-text="state"
                          item-value="value"
                          single-line
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="2" cols="12" class="ma-0 py-3 d-flex align-center">
                      <span class="black--text font-weight-bold">Âge :</span>
                    </v-col>
                    <v-col md="10" cols="12" class="ma-0 py-3 d-flex align-center">
                      <span class="text--black px-1">{{boost_order.age_range[0] }}</span>
                          <v-range-slider
                              track-color="grey"
                              color="black"
                              :error-messages="validation_errors.minAge"
                              v-model="boost_order.age_range"
                              max="65"
                              min="18"
                              hide-details
                              class="align-center"
                          >
                          </v-range-slider>
                      <span class="text--primary px-1">{{boost_order.age_range[1] + '+'}} </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="8" cols="12" class="py-2 d-flex align-center">
                      <v-btn @click="calcul_audience" :loading="calcul_loading" class="black-btn" tile outlined depressed>
                        CALCULER
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="4" cols="12" class="py-2 d-flex align-center">
                      <v-btn @click="devis_cancel" class="w-100 white-btn" color="#FFF" :style="'border: 1px solid black;'"
                             text tile outlined depressed>
                        ANNULER
                      </v-btn>
                    </v-col>
                    <v-col md="4" cols="12" class="py-2 d-flex align-center">
                      <v-btn @click="devis_demande" :loading="loading_devis" class="black-btn" tile outlined depressed>
                        DEMANDER UN DEVIS
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="merci_modal_show" width="500">
        <v-card class="merci-modal">
          <v-card-text>
            <v-container class="d-flex flex-column justify-center align-center">
              <v-icon class="font-weight-medium black--text text-h1" color="black">mdi-check-circle-outline</v-icon>
              <p class="font-weight-medium black--text text-h6">Merci!</p>
              <p class="font-weight-medium black--text text-subtitle-1">Votre commande a bien été prise en compte</p>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-container>
              <v-row>
                <v-col cols=12 class="d-flex justify-center align-center">
                  <v-btn class="black-btn" @click="merci_modal_show = false" tile outlined depressed>
                    FERMER
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-dialog>

		</v-container>

		<Footer/>
	</div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import Navbar from '@/components/Navbar.vue'
import ApiService from '@/services/api.service';

export default {
	mounted(){
    // this.get_center_of_interest();
		this.fetchData(`${this.$app_url}/api/v1/agency/booster`);
	},

	data() {
		return {
      validation_quantity: false,
      validation_description: false,
      distance:15,
      center: {lat:48.856614, lng:2.3522219},
			map_modal_show: false,
			boost_order_modal_show: false,
			merci_modal_show:false,

			current_page: 1,
			last_page: 1,
			loading: false,
			calcul_loading: false,

      loading_devis: false,
      audience_count: '__',

      validation_errors: {
        boosterId:null,
        name:null,
        comment:null,
        description:null,
        is_sms: false,
        is_email: false,
        zipCode:null,
        distance:null,
        gender:null,
        age_range:null,
        minAge:null,
        maxAge:null,
        mailQuantity:null,
        smsQuantity:null,
      },

      interet_items: [],

      gender_items: [
        { state: 'Hommes et Femmes', value: 'all' },
        { state: 'Hommes', value: 'male' },
        { state: 'Femmes', value: 'female' },
      ],

			activation_headers: [
				{ text: 'Offres', value: 'name', class:"black--text" },
				{ text: 'Description', value: 'description', class:"black--text" },
				{ text: 'Commander', class:"black--text"},
			],

			activation_data: [],

			boost_order: {
				boosterId:null,
				name:null,
				description:null,
				comment:'',
				is_sms: false,
				is_email: false,
				zipCode:null,
				distance:null,
				gender:"all",
        age_range: [18, 65],
				minAge: 0,
				maxAge: 0,
				mailQuantity: 10,
				smsQuantity: 10,
			},

			quantity_select: [10, 50, 100, 200, 500, 1000],
		}
	},

	components: {
		Footer,
		Navbar,
	},

	computed: {
		get_profile() {
			if (localStorage.getItem('set_profile')) {
				return localStorage.getItem('set_profile')
			}
			else {
				return null
			}
		}
	},

	methods: {
    // get_center_of_interest() {
    //   ApiService.get(`${this.$app_url}/api/v1/agency/booster/center_of_interest_categories`)
    //       .then((res) => {
    //         this.interet_items = res.data.data;
    //         this.boost_order.centerOfInterestId = res.data.data[0].id;
    //       })
    //       .catch(err => console.log(err))
    // },

		fetchData(url) {
			this.loading = true;
			ApiService.get(url)
				.then((res) => {
					console.log(res)
					this.current_page = res.data.current_page
					this.last_page = res.data.last_page
					this.activation_data = res.data.data
					this.loading = false;
				})
				.catch(err => console.log(err))
		},

		go_to_page(page) {
			this.fetchData(`${this.$app_url}/api/v1/agency/booster?page=${page}`)
		},

		commad_modal(item) {
			this.boost_order_modal_show  =true
			this.boost_order.boosterId= item.id
			this.boost_order.name= item.name
			this.boost_order.description= ''
			this.boost_order.is_email= false
			this.boost_order.is_sms= false
		},

		confirm_order() {
      if (this.boost_order.description === '') {
        this.validation_description = true;
      } else {
        this.validation_description = false;

        if (this.boost_order.name === 'À la carte') {
          if (this.boost_order.is_sms === false && this.boost_order.is_email === false){
            this.validation_quantity = true;
          }else {
            this.validation_quantity = false;
            this.boost_order_modal_show=false
            this.map_modal_show=true
          }
        }
        else {
          this.boost_order_modal_show=false
          this.map_modal_show=true
        }

        if (this.boost_order.is_sms === false && this.boost_order.is_email === false){
          this.validation_quantity = true;
        }else {
          this.validation_quantity = false;
          this.boost_order_modal_show=false
          this.map_modal_show=true
        }
      }
		},

    calcul_audience() {
      this.resetValidation();
      this.calcul_loading = true;
      const zone_form = {
        zipCode: this.boost_order.zipCode,
        distance: this.boost_order.distance,
        gender: this.boost_order.gender,
        age_range: this.boost_order.age_range,
      }
      ApiService.post(`${this.$app_url}/api/v1/agency/booster/chalandise_zone`, zone_form)
          .then((response) => {
            this.audience_count = response.data.data
          })
          .catch(err => this.validation_errors = err.response.data.errors)
          .finally(() => this.calcul_loading = false)
    },

    devis_demande() {
    this.loading_devis = true;
    let data = this.boost_order;

    data.minAge = this.boost_order.age_range[0];
    data.maxAge = this.boost_order.age_range[1];
    data.populationCount = this.audience_count;

    ApiService.post(`${this.$app_url}/api/v1/agency/booster/order`, data)
        .then((response) => {
          this.audience_count = response.data.data
          this.map_modal_show = false;
          this.merci_modal_show = true;
          this.devis_cancel();
        })
        .catch(err => this.validation_errors = err.response.data.errors)
        .finally(() => this.loading_devis = false)
    },

    devis_cancel() {
      this.audience_count = '__';
      this.validation_description = false;
      this.validation_quantity = false;
      this.validation_errors  = {};
      this.boost_order = {
        boosterId:null,
        name:null,
        description:null,
        is_sms: false,
        is_email: false,
        zipCode:null,
        distance:null,
        gender:"all",
        age_range: [18, 65],
        minAge: 0,
        maxAge: 0,
        mailQuantity: 10,
        smsQuantity: 10,
      };

      this.boost_order_modal_show=false
      this.map_modal_show = false
    },

    resetValidation() {
      this.validation_errors = {
        boosterId:null,
        name:null,
        comment:null,
        description:null,
        is_sms: false,
        is_email: false,
        zipCode:null,
        distance:null,
        gender:null,
        age_range:null,
        minAge:null,
        maxAge:null,
        mailQuantity:null,
        smsQuantity:null,
      };
    },

    geolocation() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
      });
    }
	}
}
</script>

<style lang="scss">
.v-dialog {
	.activation-modal{
		.modal-title{
			display: flex;
			flex-direction: column;
			text-transform: uppercase;

			&:after {
				content : '';
				display: block;
				background: #000;
				width :50px;
				height: 3px;
			}

			.close-modal{
				position: absolute;
				left: 5px;
				top: 5px;
				color: #000 !important;
			}
		}

		.close-modal{
			position: relative;
			left: 0;
			top: 0;
			color: #000 !important;
		}
	}
}
</style>
