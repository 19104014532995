<template>

  <div class="page-wrapper grey lighten-4">
    <div class="white">
      <v-container>
        <v-row no-gutters>
          <v-app-bar color="white" flat height="50">
            <v-spacer></v-spacer>
            <img src="~@/assets/img/logo.png" alt="Mynoblessa">
            <v-spacer></v-spacer>
          </v-app-bar>
        </v-row>
      </v-container>
    </div>
    <v-container>
      <v-dialog v-model="agency_modal" width="450">
        <v-card>
          <v-card-title class="modal-title headline font-weight-bold">
            <v-btn icon class="close-modal" @click="go_to_login">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text class="py-2 text-center px-16">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <h2 class="font-weight-bold black--text">
                    CRÉATION COMPTE<br>MYNOBLESSA
                  </h2>
                </v-col>
                <v-col cols="12">
                  <v-divider class="border-warning"></v-divider>
                </v-col>
                <v-col cols="12">
                  <h4 class="black--text" >
                    Votre compte vient d’être créé avec succès !
                  </h4>

                </v-col>
                <v-col cols="12">
                  <v-btn @click="go_to_login" class="text-decoration-underline" text>
                    Connectez-vous
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-row class="py-4">
        <v-col md="4" cols="12" class="d-flex flex-column align-start justify-center">
          <p class="text-h6 ma-0 pl-3" style="border-left:4px solid #000;">INFORMATIONS MAGASIN</p>
        </v-col>

        <v-col md="4" cols="12" class="d-flex align-start justify-center">
          <p class="text-h6 ma-0 pl-3">{{ magasin_form.name}}</p>
        </v-col>

        <v-col md="4" cols="12"></v-col>
      </v-row>

      <v-row  class="white py-4 pb-12">
        <v-col>
          <v-form class="magasin-details-form">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <p>Vérifiez et complétez les informations</p>
                </v-col>

                <v-col cols="12">
                  <v-text-field class="some-style ma-0 pa-0 white borderless-input" v-model="magasin_form.name" label="Nom du magasin" readonly></v-text-field>
                </v-col>

                <v-col md="4" cols="12">
                  <v-text-field class="some-style ma-0 pa-0 borderless-input" v-model="magasin_form.address" label="Adresse" readonly></v-text-field>
                </v-col>

                <v-col md="4" cols="12">
                  <v-text-field class="some-style ma-0 pa-0 borderless-input" v-model="magasin_form.zipCode" label="Code Postal" readonly></v-text-field>
                </v-col>

                <v-col md="4" cols="12">
                  <v-text-field class="some-style ma-0 pa-0 borderless-input" v-model="magasin_form.ville" label="Ville" readonly></v-text-field>
                </v-col>

                <v-col md="4" cols="12">
                  <v-text-field class="some-style ma-0 pa-0 borderless-input" v-model="magasin_form.email" label="E-mail" readonly></v-text-field>
                </v-col>

                <v-col md="4" cols="12">
                  <v-text-field class="some-style ma-0 pa-0 borderless-input" v-model="magasin_form.phone" label="Téléphone fixe" readonly></v-text-field>
                </v-col>

                <v-col md="4" cols="12">
                  <v-text-field class="some-style ma-0 pa-0 borderless-input" v-model="magasin_form.mobile" label="Téléphone mobile" readonly></v-text-field>
                </v-col>

                <v-col md="4" cols="12">
                  <v-text-field class="ma-0 pa-0" v-model="magasin_form.openingTime" :error-messages="validation_errors.openingTime" label="Horaires d’ouverture (Lundi – Vendredi)" required></v-text-field>
                </v-col>

                <v-col md="4" cols="12">
                  <v-text-field class="ma-0 pa-0" v-model="magasin_form.openingTimeSaturday" label="Horaires d’ouverture (Samedi)" required></v-text-field>
                </v-col>

                <v-col md="4" cols="12">
                  <v-text-field class="ma-0 pa-0" v-model="magasin_form.openingTimeSunday" label="Horaires d’ouverture (Dimanche)" required></v-text-field>
                </v-col>

                <v-col md="4" cols="12">
                  <v-text-field v-if="magasin_form.collaborators[0]" class="ma-0 pa-0" v-model="magasin_form.collaborators[0].name" :error-messages="validation_errors[`collaborators.0.name`]" label="Responsable du magasin" required></v-text-field>
                  <v-text-field v-else class="ma-0 pa-0" v-model="magasin_form.reponsableNom" :error-messages="validation_errors[`collaborators.0.name`]" label="Responsable du magasin" required></v-text-field>
                </v-col>

                <v-col md="4" cols="12">
                  <v-text-field v-if="magasin_form.collaborators[0]" class="ma-0 pa-0" v-model="magasin_form.collaborators[0].mobile_phone" :error-messages="validation_errors[`collaborators.0.mobile_phone`]" label="Téléphone mobile" required></v-text-field>
                  <v-text-field v-else class="ma-0 pa-0" v-model="magasin_form.responsableTel" :error-messages="validation_errors[`collaborators.0.mobile_phone`]" label="Téléphone mobile" required></v-text-field>
                </v-col>

                <v-col md="4" cols="12"></v-col>

                <v-col v-for="index in 3" md="4" cols="12" v-bind:key="index">
                  <v-text-field v-if="magasin_form.collaborators[index]" class="ma-0 pa-0" v-model="magasin_form.collaborators[index].name" :error-messages="validation_errors[`collaborators.${index}.name`]" :label="`Collaborateur habilité ${index}`" required></v-text-field>
                  <v-text-field v-else class="ma-0 pa-0" :label="`Collaborateur habilité ${index}`" v-model="magasin_form.newCollaborators[index]" :error-messages="validation_errors[`collaborators`]" required></v-text-field>
                </v-col>

                <v-col md="4" cols="12">
                  <v-text-field type="password" class="ma-0 pa-0" v-model="magasin_form.password" :error-messages="validation_errors.password" label="Mot de passe" required></v-text-field>
                </v-col>

                <v-col md="4" cols="12">
                  <v-text-field type="password" class="ma-0 pa-0" v-model="magasin_form.password_confirmation" :error-messages="validation_errors.password_confirmation" label="Confirmation mot de passe" required></v-text-field>
                </v-col>

                <v-col md="4" cols="12"></v-col>

                <v-col md="4" cols="12">
                  <v-text-field class="ma-0 pa-0" v-model="magasin_form.rib" :error-messages="validation_errors.rib" label="RIB" required></v-text-field>
                </v-col>

                <v-col md="4" cols="12">
                  <v-text-field class="ma-0 pa-0" v-model="magasin_form.tva" :error-messages="validation_errors.tva" label="N° de TVA intracommunautaire" required></v-text-field>
                </v-col>

                <v-col md="4" cols="12"></v-col>

                <v-col md="4" cols="12">
                  <v-text-field class="ma-0 pa-0" v-model="magasin_form.tradeRegistry" :error-messages="validation_errors.tradeRegistry" label="N° de registre commerce" required></v-text-field>
                </v-col>

                <v-col md="4" cols="12">
                  <v-text-field class="ma-0 pa-0" v-model="magasin_form.siret" :error-messages="validation_errors.siret" label="N° de Siret" required></v-text-field>
                </v-col>

                <v-col md="4" cols="12"></v-col>

              </v-row>
              <v-row>
                <v-col cols="12 d-flex justify-center">
                  <v-btn class="black-btn" :loading="basy" @click="submit_form()" tile outlined depressed>
                    VALIDER
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>
import ApiService from '@/services/api.service';

export default {
  created() {
    if (!this.token) {
      this.$router.push('/login')
    } else {
      console.log("TOKEN => ", this.token);
    }
  },
  mounted() {
    ApiService.get(`${this.$app_url}/api/v1/agency/registration/${this.token}`)
    .then((resp) => {
      console.log(resp.data.data.name)
      this.magasin_form.name = resp.data.data.name
      this.magasin_form.address = resp.data.data.address
      this.magasin_form.phone = resp.data.data.phone
      this.magasin_form.openingTime = resp.data.data.opening_time
      this.magasin_form.zipCode = resp.data.data.postal_code
      this.magasin_form.ville = resp.data.data.city
      this.magasin_form.mobile = resp.data.data.mobile_phone
      this.magasin_form.openingTimeSaturday = resp.data.data.opening_time_saturday
      this.magasin_form.openingTimeSunday = resp.data.data.opening_time_sunday
      this.magasin_form.email = resp.data.data.email
      this.magasin_form.rib = resp.data.data.rib === null ? "" : resp.data.data.rib
      this.magasin_form.siret = resp.data.data.siret === null ? "" : resp.data.data.siret
      this.magasin_form.tradeRegistry = resp.data.data.rc === null ? "" : resp.data.data.rc
      this.magasin_form.tva = resp.data.data.tva === null ? "" : resp.data.data.tva
      this.magasin_form.collaborators = resp.data.data.collaborators
    })
    .catch(err => console.log(err.message))
  },
  data() {
    return {
      agency_modal: false,
      basy: false,
      validation_errors: {},
      magasin_form : {
        name: "NOBLESSA PARIS",
        address: "1668 Chemin des Terriers",
        phone: "01 26 73 68 48",
        openingTime: "09:30 – 12:30, 14:00 – 19:00",
        responsable_magasin: "Agnès Mech",
        collaborateur_habilite_1: "Agnès Mech",
        collaborateur_habilite_2: "Agnès Mech",
        collaborateur_habilite_3: "Agnès Ibro",
        collaborators: [],
        newCollaborators: [],
        zipCode: "75002",
        ville: "Paris",
        mobile: "06 31 67 09 51",
        openingTimeSaturday: "09:30 – 19:00",
        email: "toulon@noblessa.fr",
        openingTimeSunday: "Fermé",
        password:"",
        password_confirmation:"",
        rib: "FR 763000100794123456789018",
        tva: "FR 7630001007941234567890185",
        tradeRegistry: "RCS PARIS B 517 403 572",
        siret: "362 521 879 00034",
        reponsableNom: '',
        responsableTel: '',
      }
    }
  },

  props: ['token'],

  methods: {
    go_to_login() {
      this.$router.push({ name: 'Login'})
    },
    submit_form() {
      this.validation_errors = {}
      this.basy = true
      let data = this.magasin_form
      if (data.collaborators.length === 0) {
        data.collaborators.push({ name: this.magasin_form.reponsableNom, mobile_phone: this.magasin_form.responsableTel })
        data.newCollaborators.forEach((item) => {
          if (item !== null) {
            data.collaborators.push({ name: item })
          }
        })
      }
      else if (data.collaborators.length < 3) {
        data.newCollaborators.forEach((item) => {
          if (item !== null) {
            data.collaborators.push({ name: item })
          }

        })
      }

      let newColl = [];

      data.collaborators.forEach((item, index) => {
        if (index === 0) {
          item.isManager = 1
          newColl.push(item);
        } else {
          for (let i = 1 ; i <= 3 ; i++){
            if (index === i) {
              if (item.name !== '') {
                item.isManager = 0
                newColl.push(item);
              }
            }
          }
        }
      });

      data.collaborators = newColl;

      if (this.magasin_form.tradeRegistry ===  "") {
        delete data.tradeRegistry;
      }

      if (this.magasin_form.tva ===  "") {
        delete data.tva;
      }

      if (this.magasin_form.rib ===  "") {
        delete data.rib;
      }

      if (this.magasin_form.siret ===  "") {
        delete data.siret;
      }

      ApiService.post(`${this.$app_url}/api/v1/agency/registration/${this.token}`, data)
          .then((res) => {
            if (res.status === 200){
              this.agency_modal = true
            }
          })
          .catch(err => this.validation_errors = err.response.data.errors)
          .finally(()=> {
            this.basy = false
          })

    },
  }
}
</script>

<style lang="scss">
.magasin-details-form .v-input .v-label{
  font-weight: bold !important;
  color: #000 !important;
}

.some-style .v-input__slot::before {
  border-style: none !important;
}
</style>