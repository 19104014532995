<template>

	<div class="page-wrapper grey lighten-4">
		<Navbar/>

		<v-container>
			<v-row class="py-4">
				<v-col md="8" cols="12" class="d-flex flex-column align-start">
					<v-btn :to="{name:'ReseauxSociaux'}" tile depressed class="subtitle-2 text-lowercase pl-0">
						<v-icon class="font-weight-regular pr-2" color="black">mdi-arrow-left</v-icon> Retour
					</v-btn>
					<p class="text-h6 ma-0 pl-3" style="border-left:4px solid #000;">Mes réseaux sociaux</p>
				</v-col>
        <v-col md="4" cols="12" class="d-flex flex-row align-end justify-end">
          <v-btn class="px-2" :class="{'black-btn' : post_data_show}" style="font-size: 12px;" tile outlined depressed @click="show_post()">
            POST
          </v-btn>

          <v-btn class="px-2" :class="{'black-btn' : planning_editorial_data_show}" style="font-size: 12px;" tile outlined depressed @click="show_planning_editorial()">
            PLANNING EDITORIAL
          </v-btn>
        </v-col>
			</v-row>

			<v-row class="white py-4 pb-12 elevation-1">
				<v-col>
					<v-data-table
              v-if="post_data_show"
              class="reseaux-sociaux-table"
              :headers="reseaux_sociaux_headers"
              :items="reseaux_sociaux_data"
              :disable-sort="true"
              :hide-default-footer="true"
              :loading="loading"
              loading-text="Chargement... veuillez patienter">
						<template v-slot:item="row">
							<tr>
								<td>
									{{row.item.platform.name}}
								</td>
								<td>
									{{row.item.date | change_date_format()}}
								</td>
								<td>
                  {{row.item.week_no}}
								</td>
								<td>
									<v-btn icon small color="black">
										<v-icon @click="detail(row.item)"  dark>mdi-eye</v-icon>
									</v-btn>
								</td>
								<td>
									<v-btn @click="download(row.item)" icon small color="black">
										<v-icon dark>mdi-download</v-icon>
									</v-btn>
								</td>
							</tr>
						</template>
					</v-data-table>

					<v-col  v-if="post_data_show">
						<template>
						<div class="text-right">
							<v-pagination
								color="grey"
								v-model="current_page"
								:length="last_page"
								@input="go_to_page"
								prev-icon="mdi-menu-left"
								next-icon="mdi-menu-right"
							></v-pagination>
						</div>
						</template>
					</v-col>

          <v-data-table
              v-if="planning_editorial_data_show"
              :headers="planning_editorial_headers"
              :items="planning_editorial_data"
              class="reseaux-sociaux-table"
              :disable-sort="true" :hide-default-footer="true"
              :loading="loading"
              loading-text="Chargement... veuillez patienter">
            <template v-slot:item="row">
              <tr>
                <td>
                  {{row.item.date | change_date_format()}}
                </td>
                <td>
                  <v-btn icon small color="black">
                    <v-icon @click="detail(row.item)"  dark>mdi-eye</v-icon>
                  </v-btn>
                </td>
                <td>
                  <v-btn @click="download(row.item)" icon small color="black">
                    <v-icon dark>mdi-download</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>

          <v-col v-if="planning_editorial_data_show">
            <template>
              <div class="text-right">
                <v-pagination
                    color="grey"
                    v-model="current_page"
                    :length="last_page"
                    @input="planing_go_to_page"
                    prev-icon="mdi-menu-left"
                    next-icon="mdi-menu-right"
                ></v-pagination>
              </div>
            </template>
          </v-col>
				</v-col>

			</v-row>

		</v-container>

		<Footer/>
	</div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import Navbar from '@/components/Navbar.vue'
import ApiService from '@/services/api.service';
import axios from "axios";
import moment from "moment";
export default {
  created() {
    if (!this.item) {
      this.activeItem = 'post'
    }
    else {
      this.activeItem = "planing"
    }
  },
  mounted(){
    if (this.activeItem === 'post') {
      this.current_page = 1
      this.last_page = 1
      this.post_data_show = true
      this.planning_editorial_data_show = false
      this.fetchData(`${this.$app_url}/api/v1/agency/social_media/post/${this.current_year}/${this.$route.params.id}`);
    }
    else if (this.activeItem === 'planing') {
      this.current_page = 1
      this.last_page = 1
      this.post_data_show = false
      this.planning_editorial_data_show = true
      this.fetch_planing_data(`${this.$app_url}/api/v1/agency/social_media/editorial_planning/${this.current_year}/${this.$route.params.id}`);
    }
	},
	filters: {
		change_date_format(date) {
			const result = moment(date).locale("fr").format("MMMM YYYY");
			return result;
		},
	},
	data() {
		return {
      post_data_show: false,
      planning_editorial_data_show: false,
      activeItem: 'post',
      current_year: moment().year(),
			current_page: 1,
			last_page: 1,
			loading: false,

			reseaux_sociaux_headers: [
				{ text: 'Plateforme', value: 'platform', class:"black--text"},
				{ text: 'Date', value: 'date', class:"black--text"},
				{ text: 'Semaine', value: 'week_no', class:"black--text"},
				{ text: 'Afficher', class:"black--text"},
				{ text: 'Télécharger', class:"black--text"},
			],

			reseaux_sociaux_data: [],

      planning_editorial_headers: [
        { text: 'Date', value: 'date', class:"black--text"},
        { text: 'Afficher', class:"black--text"},
        { text: 'Télécharger', class:"black--text"},
      ],

      planning_editorial_data: [],

		}
	},

	components: {
		Footer,
		Navbar,
	},

	methods: {
    show_post() {
      this.current_page = 1
      this.last_page = 1
      this.post_data_show = true
      this.planning_editorial_data_show = false
      this.fetchData(`${this.$app_url}/api/v1/agency/social_media/post/${this.current_year}/${this.$route.params.id}`);
    },

    show_planning_editorial() {
      this.current_page = 1
      this.last_page = 1
      this.post_data_show = false
      this.planning_editorial_data_show = true
      this.fetch_planing_data(`${this.$app_url}/api/v1/agency/social_media/editorial_planning/${this.current_year}/${this.$route.params.id}`);
    },

		fetchData(url) {
			this.loading = true;
			ApiService.get(url)
				.then((res) => {
					this.current_page = res.data.meta.current_page
					this.last_page = res.data.meta.last_page
					this.reseaux_sociaux_data = res.data.data
					this.loading = false;
				})
				.catch(err => console.log(err))
		},

    fetch_planing_data(url) {
      this.loading = true
      ApiService.get(url)
          .then((res) => {
            this.planning_editorial_data = res.data.data
            this.current_page = res.data.current_page
            this.last_page = res.data.last_page
            this.loading = false
          })
          .catch(err => console.log(err))
    },

		go_to_page(page) {
			this.fetchData(`${this.$app_url}/api/v1/agency/social_media/post/${this.current_year}/${this.$route.params.id }?page=${page}`)
		},

    planing_go_to_page(page) {
      this.fetch_planing_data(`${this.$app_url}/api/v1/agency/social_media/editorial_planning/${this.current_year}/${this.$route.params.id}?page=${page}`);

    },

		download(item) {
      this.$confirm(
          {
            message: `Voulez-vous télécharger le fichier ?`,
            button: { no: 'Non', yes: 'Oui' },
            callback: confirm => {
              if (confirm) {
                axios({
                  url: `${this.$app_url}/api/v1/user/file/${item.file.id}`,
                  method: 'GET',
                  responseType: 'blob',
                }).then((response) => {
                  const ext = response.data.type.split('/')[1];
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', `${item.file.name}.${ext}`);
                  document.body.appendChild(link);
                  link.click();
                });
              }
            }
          }
      )

		},

    detail(item) {
      if (item.file_id) {
        item.file = item.file_id;
        delete item.file_id;
      }
      this.$router.push({
        name: 'ReseauxSociauxDetail',
        params: {
          item: item,
        }
      })
    },
	}
}
</script>


<style lang="scss">
.reseaux-sociaux-table {
	border-radius: 0 !important;

	thead tr{
		th {
			color : #000 !important;
			border-bottom : 1px solid #000 !important;
		}
	}

	tbody tr {
		&:hover,
		&:hover td .v-btn--icon {
			background: #000 !important;
			color: #fff !important;
		}

		td {
			border : none !important;

			.v-btn--icon .v-icon{
				font-size: 18px;

				&:hover{
					font-size: 24px;
				}
			}
		}
	}
}
</style>
