<template>

  <div class="page-wrapper grey lighten-4">
    <div class="white">
      <v-container>
        <v-row no-gutters>
          <v-app-bar color="white" flat height="50">
            <v-spacer></v-spacer>
            <img src="~@/assets/img/logo.png" alt="Mynoblessa">
            <v-spacer></v-spacer>
          </v-app-bar>
        </v-row>
      </v-container>
    </div>
    <v-container>
      <v-row class="py-4">
        <v-col md="4" cols="12" class="d-flex flex-column align-start justify-center">
          <v-btn @click="agency_details()" tile depressed class="subtitle-2 text-lowercase pl-0">
            <v-icon class="font-weight-regular pr-2" color="black">mdi-arrow-left</v-icon> Retour
          </v-btn>
          <p class="text-h6 ma-0 pl-3" style="border-left:4px solid #000;">INFORMATIONS MAGASIN</p>
        </v-col>

        <v-col md="4" cols="12" class="d-flex align-start justify-center">
          <p class="text-h6 ma-0 pl-3">{{ agency_data.name }}</p>
        </v-col>

        <v-col md="4" cols="12"></v-col>
      </v-row>

      <v-row  class="white py-4 pb-12">
        <v-col>
          <v-form class="magasin-details-form">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <p>Vérifiez et complétez les informations</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="4" cols="12">
                  <v-text-field class="ma-0 pa-0" v-model="magasin_form.name" :error-messages="validation_errors.name" label="Nom du magasin"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="4" cols="12">
                  <v-text-field class="ma-0 pa-0" v-model="magasin_form.address" :error-messages="validation_errors.address" label="Adresse"></v-text-field>
                </v-col>
                <v-spacer></v-spacer>

                <v-col md="4" cols="12">
                  <v-text-field class="ma-0 pa-0" v-model="magasin_form.zipCode"  :error-messages="validation_errors.zipCode" label="Code Postal"></v-text-field>
                </v-col>

                <v-col md="4" cols="12">
                  <v-text-field class="ma-0 pa-0" v-model="magasin_form.city" :error-messages="validation_errors.city" label="Ville"></v-text-field>
                </v-col>

                <v-col md="4" cols="12">
                  <v-text-field class="ma-0 pa-0" v-model="magasin_form.email" :error-messages="validation_errors.email" label="E-mail"></v-text-field>
                </v-col>

                <v-col md="4" cols="12">
                  <v-text-field class="ma-0 pa-0" v-model="magasin_form.phone" :error-messages="validation_errors.phone" label="Téléphone fixe"></v-text-field>
                </v-col>

                <v-col md="4" cols="12">
                  <v-text-field class="ma-0 pa-0" v-model="magasin_form.mobile" :error-messages="validation_errors.mobile" label="Téléphone mobile"></v-text-field>
                </v-col>

                <v-col md="4" cols="12">
                  <v-text-field class="ma-0 pa-0" v-model="magasin_form.openingTime" :error-messages="validation_errors.openingTime" label="Horaires d’ouverture (Lundi – Vendredi)" required></v-text-field>
                </v-col>

                <v-col md="4" cols="12">
                  <v-text-field class="ma-0 pa-0" v-model="magasin_form.openingTimeSaturday" label="Horaires d’ouverture (Samedi)" required></v-text-field>
                </v-col>

                <v-col md="4" cols="12">
                  <v-text-field class="ma-0 pa-0" v-model="magasin_form.openingTimeSunday" label="Horaires d’ouverture (Dimanche)" required></v-text-field>
                </v-col>

                <v-col md="4" cols="12">
                  <v-text-field class="ma-0 pa-0" v-model="magasin_form.collaborators[0].name" :error-messages="validation_errors[`collaborators.0.name`]" label="Responsable du magasin" required></v-text-field>
                </v-col>

                <v-col md="4" cols="12">
                  <v-text-field class="ma-0 pa-0" v-model="magasin_form.collaborators[0].mobile_phone" :error-messages="validation_errors[`collaborators.0.mobile_phone`]" label="Téléphone mobile" required></v-text-field>
                </v-col>

                <v-col md="4" cols="12"></v-col>

                <v-col v-for="index in 3" md="4" cols="12" v-bind:key="index">
                  <v-text-field v-if="magasin_form.collaborators[index]" class="ma-0 pa-0" v-model="magasin_form.collaborators[index].name" :error-messages="validation_errors[`collaborators.${index}.name`]" :label="`Collaborateur habilité ${index}`" required></v-text-field>
                  <v-text-field v-else class="ma-0 pa-0" :label="`Collaborateur habilité ${index}`" v-model="magasin_form.newCollaborators[index -1]" :error-messages="validation_errors[`collaborators`]" required></v-text-field>
                </v-col>

                <v-col md="4" cols="12">
                  <v-text-field type="password" class="ma-0 pa-0" v-model="magasin_form.password" :error-messages="validation_errors.password" label="Mot de passe" required></v-text-field>
                </v-col>

                <v-col md="4" cols="12">
                  <v-text-field type="password" class="ma-0 pa-0" v-model="magasin_form.password_confirmation" :error-messages="validation_errors.password_confirmation" label="Confirmation mot de passe" required></v-text-field>
                </v-col>

                <v-col md="4" cols="12"></v-col>

                <v-col md="4" cols="12">
                  <v-text-field class="ma-0 pa-0" v-model="magasin_form.rib" :error-messages="validation_errors.rib" label="RIB" required></v-text-field>
                </v-col>

                <v-col md="4" cols="12">
                  <v-text-field class="ma-0 pa-0" v-model="magasin_form.tva" :error-messages="validation_errors.tva" label="N° de TVA intracommunautaire" required></v-text-field>
                </v-col>

                <v-col md="4" cols="12"></v-col>

                <v-col md="4" cols="12">
                  <v-text-field class="ma-0 pa-0" v-model="magasin_form.tradeRegistry" :error-messages="validation_errors.tradeRegistry" label="N° de registre commerce" required></v-text-field>
                </v-col>

                <v-col md="4" cols="12">
                  <v-text-field class="ma-0 pa-0" v-model="magasin_form.siret" :error-messages="validation_errors.siret" label="N° de Siret" required></v-text-field>
                </v-col>

                <v-col md="4" cols="12"></v-col>

              </v-row>
              <v-row>
                <v-col cols="12 d-flex justify-center">
                  <v-btn class="black-btn" :loading="basy" @click="submit_form()" tile outlined depressed>
                    VALIDER
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>
import ApiService from '@/services/api.service';

export default {
  data() {
    return {
      agency_data: null,
      basy: false,
      validation_errors: {},
      magasin_form: {
        name: "",
        address: "",
        phone: "",
        openingTime: "",
        responsable_magasin: {},
        collaborators: [],
        newCollaborators: [],
        zipCode: "",
        city: "",
        mobile: "",
        openingTimeSaturday: "",
        email: "",
        openingTimeSunday: "",
        password: "",
        password_confirmation: "",
        rib: "",
        tva: "",
        tradeRegistry: "",
        siret: "",
      }
    }
  },
  mounted() {
    this.fetchProfil();
  },
  methods: {
    fetchProfil() {
      ApiService.get(`${this.$app_url}/api/v1/agency/my-profile`)
          .then((res) => {
            console.log("info => ", res.data);
            this.agency_data = res.data;

            this.magasin_form.name = this.agency_data.name
            this.magasin_form.address = this.agency_data.address
            this.magasin_form.phone = this.agency_data.phone
            this.magasin_form.openingTime = this.agency_data.opening_time
            this.magasin_form.zipCode = this.agency_data.postal_code
            this.magasin_form.city = this.agency_data.city
            this.magasin_form.mobile = this.agency_data.mobile_phone
            this.magasin_form.openingTimeSaturday = this.agency_data.opening_time_saturday
            this.magasin_form.openingTimeSunday = this.agency_data.opening_time_sunday
            this.magasin_form.email = this.agency_data.email
            this.magasin_form.rib = this.agency_data.rib
            this.magasin_form.intracommunautaire = this.agency_data.tva
            this.magasin_form.tva = this.agency_data.tva
            this.magasin_form.siret = this.agency_data.siret
            this.magasin_form.tradeRegistry = this.agency_data.rc
            this.magasin_form.collaborators = this.agency_data.collaborators;
            this.magasin_form.responsable_magasin = this.agency_data.manager;
          })
          .catch(err => console.log(err))
    },

    submit_form() {
      this.validation_errors = {}
      this.basy = true
      let data = this.magasin_form
      if (data.collaborators.length === 0) {
        data.collaborators.push({ name: this.magasin_form.reponsableNom , mobile_phone: this.magasin_form.responsableTel })
        data.newCollaborators.forEach((item) => {
          if (item !== null) {
            data.collaborators.push({ name: item })
          }
        })
      }
      else if (data.collaborators.length  !== 0) {
        data.newCollaborators.forEach((item) => {
          if (item !== null) {
            data.collaborators.push({ name: item })
          }

        })
      }

      let newColl = [];

      data.collaborators.forEach((item, index) => {
        if (index === 0) {
          item.isManager = 1
          newColl.push(item);
        } else {
          for (let i = 1 ; i <= 3 ; i++){
            if (index === i) {
              if (item.name !== '') {
                item.isManager = 0;
                item.mobile_phone = '';
                newColl.push(item);
              }
            }
          }
        }
      });

      data.collaborators = newColl;

      if (data.password === "") {
        delete data.password;
        delete data.password_confirmation;
      }

      if (this.magasin_form.email ===  this.agency_data.email) {
        delete data.email;
      }

      if (this.magasin_form.tradeRegistry ===  "") {
        delete data.tradeRegistry;
      }

      if (this.magasin_form.tva ===  "") {
        delete data.tva;
      }

      if (this.magasin_form.rib ===  "") {
        delete data.rib;
      }

      if (this.magasin_form.siret ===  "") {
        delete data.siret;
      }

      ApiService.put(`${this.$app_url}/api/v1/agency/update-info/${this.agency_data.id}`, data)
          .then((resp) => {
            this.$emit('flash-success-snackbar', resp.data.message);
            this.validation_errors = {}
            this.$router.push({ name: 'Profil'});
          })
          .catch((err) => {
            this.validation_errors = err.response.data.errors
          })
          .finally(() => {
            this.basy = false
          })


    },

    agency_details() {
      this.$router.push({
        name: 'Profil'
      })
    },
  }
}
</script>

<style lang="scss">
.magasin-details-form .v-input .v-label{
  font-weight: bold !important;
  color: #000 !important;
}

.some-style .v-input__slot::before {
  border-style: none !important;
}
</style>