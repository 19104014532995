<template>

	<div class="login-container">
		<div class="logo"></div>

		<v-row no-gutters class="black">
			<v-col lg="6" cols="12" class="login-img"></v-col>

			<v-col lg="6" cols="12" class="form-container d-flex justify-center align-center">

				<v-container class="text-center">
					<p class="font-weight-light ma-0 text-bienvenue">Bienvenue sur la plateforme</p>

					<p class="text-noblessa font-weight-thin">my<strong class="font-weight-light">noblessa</strong></p>

					<h5 class="text-subtitle-1 font-weight-light mb-12 mt-6">Qui êtes-vous ?</h5>

					<v-row no-gutters>
						<v-col lg="4" md="2" sm="1" cols="12"></v-col>

						<v-col lg="4" md="8" sm="10" cols="12">
							<v-radio-group v-model="selected_user">
								<v-radio
									v-for="user in users"
									:key="user.id"
									:label="user.name"
									:value="user.name"
								></v-radio>
							</v-radio-group>

							<v-btn medium outlined text tile class="my-4 font-weight-light submit-btn" color="white" @click="submit">
								c'est parti !
							</v-btn>
						</v-col>

						<v-col lg="4" md="2" sm="1" cols="12"></v-col>
					</v-row>
				</v-container>

			</v-col>
		</v-row>
	</div>

</template>

<script>
export default {
  created() {
    if (!this.$store.state.collaborators) {
      this.$router.push('/login')
    }
  },
  mounted() {
    this.users = this.$store.state.collaborators
  },
  data() {
		return {
			selected_user : null,

			users : [],
		}
	},

	computed: {

    },

	methods: {
		submit() {
      if (this.selected_user) {
        localStorage.setItem('set_profile', this.selected_user);
        this.$router.push('/menu')
      }
      else {
        this.$emit("flash-warning-snackbar", "Aucun utilisateur choisi");
      }
		}
	}
}
</script>