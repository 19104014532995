import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuelidate from "vuelidate";
import vuetify from './plugins/vuetify';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueConfirmDialog from 'vue-confirm-dialog'

import ApiService from './services/api.service';
ApiService.init();

import Config from './config.js'
Vue.prototype.$app_url = Config.app_url;
Vue.prototype.$base_name = Config.base_name;

Vue.use(Vuelidate);
Vue.use(VueConfirmDialog);
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

Vue.config.productionTip = false

Vue.use(VueGoogleMaps, {
  load: {
    region: 'FR',
    language: 'fr',
    key: 'AIzaSyCmq7XqArmmA2cbDG7FNoYxC9qcqCpt74Q',
    libraries: "places,drawing,geometry" // necessary for places input
  },
  installComponents: true
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
