<template>
  <v-app>
    <router-view
      @flash-success-snackbar="flashSuccessSnackbar"
      @flash-warning-snackbar="flashWarningSnackbar"
      @flash-danger-snackbar="flashDangerSnackbar"
    />
    <v-snackbar
      v-model="snackbar"
      timeout="2000"
      :value="true"
      absolute
      color="success"
      bottom
      right
      >{{ snackbar_message }}</v-snackbar
    >
    <v-snackbar
      v-model="snackbar_warning"
      timeout="2000"
      :value="true"
      absolute
      color="warning"
      bottom
      right
      >{{ snackbar_message }}</v-snackbar
    >
    <v-snackbar v-model="danger_snackbar" timeout="2000" :value="true" absolute color="red" bottom right>{{danger_snackbar_message}}</v-snackbar>
    <vue-confirm-dialog class="vue-confirm-class"></vue-confirm-dialog>
  </v-app>

</template>

<script>
export default {
  name: "App",
  data: () => ({
    snackbar: false,
    snackbar_warning: false,
    snackbar_message: null,
    danger_snackbar: false,
		danger_snackbar_message: null,
  }),
  methods: {
    flashSuccessSnackbar(message) {
      this.snackbar = true;
      this.snackbar_message = message;
    },
    flashWarningSnackbar(message) {
      this.snackbar_warning = true;
      this.snackbar_message = message;
    },
		flashDangerSnackbar(message){
			this.danger_snackbar=true
			this.danger_snackbar_message=message
    }
	}
};
</script>

<style lang="scss">
@import "~@/assets/styles/main.scss";
</style>
