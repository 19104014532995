import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../store/index.js'
import config from '../config.js'
import JwtService from "../services/jwt.service";

import Login from '../views/Login.vue'
import SelectProfil from '../views/SelectProfil.vue'
import Menu from '../views/Menu.vue'
import Profil from '../views/Profil.vue'
import Campagnes from '../views/Campagnes.vue'
import PointVente from '../views/PointVente.vue'
import ReseauxSociaux from '../views/ReseauxSociaux.vue'
import ReseauxSociauxMois from '../views/ReseauxSociauxMois.vue'
import CorporateBeauVivre from '../views/CorporateBeauVivre.vue'
import MesProduits from '../views/MesProduits.vue'
import MonKitOuverture from '../views/MonKitOuverture.vue'
import MonActivation from '../views/MonActivation.vue'
import PageNotFound from '../views/PageNotFound.vue'
import SignupAgence from "@/views/SignupAgence";
import CampagneDetails from "@/views/CampagneDetails";
import PresentaionDetails from "@/views/PresentaionDetails";
import ExempleDetails from "@/views/ExempleDetails";
import ProduitDetails from "@/views/ProduitDetails";
import ResauxSociauxDetail from "@/views/ResauxSociauxDetail";
import TglOutilDetail from "@/views/TglOutilDetail";
import HistoriqueDetails from "@/views/HistoriqueDetails";
import CampagneDetail from "@/views/CampagneDetail";
import ResetPassword from "@/views/ResetPassword";
import ChangePassword from "@/views/ChangePassword";
import UpdateInfo from "@/views/UpdateInfo";

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Menu',
		component: Menu,
		meta: {
			requiresAuth: true,
			authName: 'agency',
		}
	},
	{
		path: '/signup/:token',
		name: 'SignupAgence',
		component: SignupAgence,
		props: true,
		meta: {
			requiresAuth: false,
			authName: 'agency',
		}
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
		meta: {
			requiresAuth: false,
			authName: 'agency',
		}
	},
	{
		path: '/reset-pasword',
		name: 'ResetPassword',
		component: ResetPassword,
		meta: {
			requiresAuth: false,
			authName: 'agency',
		}
	},
	{
		path: '/change-password/:token',
		name: 'ChangePassword',
		component: ChangePassword,
		meta: {
			requiresAuth: false,
			authName: 'agency',
		}
	},
	{
		path: '/select-profil',
		name: 'SelectProfil',
		component: SelectProfil,
		props: true,
		meta: {
			requiresAuth: true,
			authName: 'agency',
		}
	},
	{
		path: '/menu',
		name: 'Menu',
		component: Menu,
		meta: {
			requiresAuth: true,
			authName: 'agency',
		}
	},
	{
		path: '/profil',
		name: 'Profil',
		component: Profil,
		meta: {
			requiresAuth: true,
			authName: 'agency',
		}
	},
	{
		path: '/update-info',
		name: 'UpdateInfo',
		component: UpdateInfo,
		props: true,
		meta: {
			requiresAuth: true,
			authName: 'agency',
		}
	},
	{
		path: '/profil/historique/detail',
		name: 'HistoriqueDetails',
		component: HistoriqueDetails,
		props: true,
		meta: {
			requiresAuth: true,
			authName: 'agency',
		}
	},
	{
		path: '/campagnes',
		name: 'Campagnes',
		component: Campagnes,
		meta: {
			requiresAuth: true,
			authName: 'agency',
		}
	},
	{
		path: '/point-vente',
		name: 'PointVente',
		component: PointVente,
		meta: {
			requiresAuth: true,
			authName: 'agency',
		}
	},
	{
		path: '/corporate-beau-vivre',
		name: 'CorporateBeauVivre',
		component: CorporateBeauVivre,
		meta: {
			requiresAuth: true,
			authName: 'agency',
		}
	},
	{
		path: '/corporate-beau-vivre/campagne/detail',
		name: 'CampagneDetail',
		component: CampagneDetail,
		props: true,
		meta: {
			requiresAuth: true,
			authName: 'agency',
		}
	},
	{
		path: '/corporate-beau-vivre/outil/detail',
		name: 'TglOutilDetail',
		component: TglOutilDetail,
		props: true,
		meta: {
			requiresAuth: true,
			authName: 'agency',
		}
	},
	{
		path: '/corporate-beau-vivre/exemple/detail',
		name: 'ExempleDetail',
		component: ExempleDetails,
		props: true,
		meta: {
			requiresAuth: true,
			authName: 'agency',
		}
	},
	{
		path: '/produits',
		name: 'MesProduits',
		component: MesProduits,
		meta: {
			requiresAuth: true,
			authName: 'agency',
		}
	},
	{
		path: '/produits/detail',
		name: 'ProduitDetail',
		component: ProduitDetails,
		props: true,
		meta: {
			requiresAuth: true,
			authName: 'agency',
		}
	},
	{
		path: '/kit-ouverture',
		name: 'MonKitOuverture',
		component: MonKitOuverture,
		meta: {
			requiresAuth: true,
			authName: 'agency',
		}
	},
	{
		path: '/kit-ouverture/campagne/detail',
		name: 'CampagneDetails',
		component: CampagneDetails,
		props: true,
		meta: {
			requiresAuth: true,
			authName: 'agency',
		}
	},
	{
		path: '/kit-ouverture/presentation/detail',
		name: 'PresentationDetails',
		component: PresentaionDetails,
		props: true,
		meta: {
			requiresAuth: true,
			authName: 'agency',
		}
	},
	{
		path: '/activation',
		name: 'MonActivation',
		component: MonActivation,
		meta: {
			requiresAuth: true,
			authName: 'agency',
		}
	},
	{
		path: '/reseaux-sociaux',
		name: 'ReseauxSociaux',
		component: ReseauxSociaux,
		meta: {
			requiresAuth: true,
			authName: 'agency',
		}
	},
	{
		path: '/reseaux-sociaux/:id',
		name: 'ReseauxSociauxMois',
		component: ReseauxSociauxMois,
		meta: {
			requiresAuth: true,
			authName: 'agency',
		}
	},
	{
		path: '/reseaux-sociaux/detail',
		name: 'ReseauxSociauxDetail',
		component: ResauxSociauxDetail,
		props: true,
		meta: {
			requiresAuth: true,
			authName: 'agency',
		}
	},
	{
		path: "*",
		component: PageNotFound
	}
]

const router = new VueRouter({
	mode: 'history',
    base: config.base_name,
	routes,
	linkExactActiveClass: 'active',
    scrollBehavior: function (to, from, savedPosition) {
        return savedPosition || { x: 0, y: 0 }
    }
})

router.beforeResolve((to, from, next) => {
    store.dispatch('loader')
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (JwtService.getToken().token && to.meta.authName === JwtService.getToken().authName) {
            store.dispatch('check_auth');
            next()
            return
        }else{
			store.dispatch('logout')
			next('/login');
		}
    } else {
        next()
    }
})

router.afterEach(() => {
    setTimeout(() => {
        store.dispatch('loader')
    }, 500);
})

export default router
