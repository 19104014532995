<template>
  <div class="page-wrapper grey lighten-4">
    <Navbar />

    <v-container>
      <v-row class="py-4">
        <v-col
          sm="8"
          cols="12"
          class="d-flex flex-column align-start justify-center"
        >
          <v-btn
            tile
            depressed
            class="subtitle-2 text-lowercase pl-0"
            @click="retour_back()"
          >
            <v-icon class="font-weight-regular pr-2" color="black"
              >mdi-arrow-left</v-icon
            >
            Retour
          </v-btn>
          <p class="text-h6 ma-0 pl-3" style="border-left:4px solid #000;">
            Mon kit d’ouverture
          </p>
        </v-col>

        <v-col
          sm="4"
          cols="12"
          class="d-flex align-end justify-sm-end justify-start"
        >
          <v-row no-gutters>
            <v-col class="text-right mt-2">
              <v-btn
                  class="w-75 charte-btn"
                  :class="{ 'black-btn': campagne_show }"
                  @click="show_campagne()"
                  tile
                  outlined
                  depressed
              >Campagne</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row class="white py-4 pb-12">
        <v-col>
          <v-data-table
            v-if="presentation_show"
            :headers="presentation_headers"
            :items="presentation_data.data"
            :disable-sort="true"
            :hide-default-footer="true"
            :loading="loading"
            loading-text="Loading... Please wait"
            class="elevation-1"
          >
            <template v-slot:item="row">
              <tr>
                <td>{{ row.item.file.name }}</td>
                <td>
                    <v-icon @click="$router.push({ name: 'PresentationDetails', params: { presentation: row.item } })" color="black">mdi-eye</v-icon>
                </td>
                <td>
                  <v-btn
                    icon
                    small
                    color="black"
                    @click="download_presentation_modal(row.item.file)"
                  >
                    <v-icon dark>mdi-download</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>

          <v-data-table
            v-if="campagne_show"
            :headers="campagne_headers"
            :items="campagne_data.data"
            :disable-sort="true"
            :hide-default-footer="true"
            :loading="loading"
            loading-text="Loading... Please wait"
            class="elevation-1"
          >
            <template v-slot:item="row">
              <tr>
                <td>{{ row.item.name }}</td>
                <td>{{ row.item.agency }}</td>
                <td>
                    <v-icon @click="$router.push({ name: 'CampagneDetails', params: { campagne: row.item } })" color="black">mdi-eye</v-icon>
                </td>
                <td>
                  <v-btn
                    icon
                    small
                    color="black"
                    @click="download_campagne_modal(row.item.file)"
                  >
                    <v-icon dark>mdi-download</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>

          <template>
            <div class="text-right">
              <v-pagination
                color="grey"
                v-model="current_page"
                :length="last_page"
                @input="go_to_page"
                prev-icon="mdi-menu-left"
                next-icon="mdi-menu-right"
              ></v-pagination>
            </div>
          </template>
        </v-col>
      </v-row>
    </v-container>

    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Navbar from "@/components/Navbar.vue";
import ApiService from "@/services/api.service";
import axios from "axios";
export default {
  data() {
    return {
      presentation_show: true,
      campagne_show: false,
      presentation_headers: [
        { text: "Communication", value: "file.name", class: "black--text" },
        { text: "Afficher", class: "black--text" },
        { text: "Télécharger", class: "black--text" },
      ],
      presentation_data: [],
      campagne_headers: [
        { text: "Support", value: "name", class: "black--text" },
        { text: "Nom du magasin", value: "agency", class: "black--text" },
        { text: "Afficher", class: "black--text" },
        { text: "Télécharger", class: "black--text" },
      ],
      campagne_data: [],
      current_page: 1,
      last_page: 1,
      loading: false,
    };
  },

  components: {
    Footer,
    Navbar,
  },
  mounted() {
    this.show_campagne();
  },
  methods: {
    fetch_campagnes(
      url = `${this.$app_url}/api/v1/agency/opening_kit/campaign_tool`
    ) {
      this.loading = true;

      ApiService.get(url)
        .then((response) => {
          this.campagne_data = response.data;
          this.current_page = response.data.current_page;
          this.last_page = response.data.last_page;
          if (response.data.message) {
            this.$emit("flash-success-snackbar", response.data.message);
          }
        })
        .catch((err) => {
          this.$emit("flash-warning-snackbar", err.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetch_presentations(
      url = `${this.$app_url}/api/v1/agency/opening_kit/presentation`
    ) {
      this.loading = true;
      ApiService.get(url)
        .then((response) => {
          this.presentation_data = response.data;
          this.current_page = response.data.current_page;
          this.last_page = response.data.last_page;
          if (response.data.message) {
            this.$emit("flash-success-snackbar", response.data.message);
          }
        })
        .catch((err) =>
          this.$emit("flash-warning-snackbar", err.response.data.message)
        )
        .finally(() => {
          this.loading = false;
        });
    },
    hide_all() {
      this.presentation_show = false;
      this.campagne_show = false;
    },

    show_presentation() {
      this.hide_all();
      this.presentation_show = true;
      this.fetch_presentations();
    },

    show_campagne() {
      this.hide_all();
      this.campagne_show = true;
      this.fetch_campagnes();
    },
    retour_back() {
      this.$router.push("/");
    },
    go_to_page(page) {
      if (this.presentation_show) {
        this.fetch_presentations(
          `${this.$app_url}/api/v1/agency/opening_kit/presentation?page=${page}`
        );
      } else {
        this.fetch_campagne(
          `${this.$app_url}/api/v1/agency/opening_kit/campaign_tool?page=${page}`
        );
      }
    },
    download_presentation_modal(file) {
      this.$confirm(
          {
            message: `Voulez-vous télécharger le fichier ?`,
            button: { no: 'Non', yes: 'Oui' },
            callback: confirm => {
              if (confirm) {
                axios({
                  url: `${this.$app_url}/api/v1/user/file/${file.id}`,
                  method: "GET",
                  responseType: "blob",
                }).then((response) => {
                  const ext = response.data.type.split("/")[1];
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", `${file.name}.${ext}`);
                  document.body.appendChild(link);
                  link.click();
                });
              }
            }
          }
      )

    },
    download_campagne_modal(file) {
      this.$confirm(
          {
            message: `Voulez-vous télécharger le fichier ?`,
            button: { no: 'Non', yes: 'Oui' },
            callback: confirm => {
              if (confirm) {
                axios({
                  url: `${this.$app_url}/api/v1/user/file/${file.id}`,
                  method: "GET",
                  responseType: "blob",
                }).then((response) => {
                  const ext = response.data.type.split("/")[1];
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", `${file.name}.${ext}`);
                  document.body.appendChild(link);
                  link.click();
                });
              }
            }
          }
      )

    },
  },
};
</script>
