<template>
  <div class="page-wrapper grey lighten-4">
    <Navbar />

    <v-container>
      <v-row class="py-4">
        <v-col
          sm="6"
          cols="12"
          class="d-flex flex-column align-start justify-center"
        >
          <v-btn tile depressed class="subtitle-2 text-lowercase pl-0" to="/">
            <v-icon class="font-weight-regular pr-2" color="black"
              >mdi-arrow-left</v-icon
            >
            Retour
          </v-btn>
          <p class="text-h6 ma-0 pl-3" style="border-left:4px solid #000;">
            Mes produits
          </p>
        </v-col>

        <v-col
          sm="6"
          cols="12"
          class="d-flex align-end justify-sm-end justify-start"
        >
          <v-row no-gutters>
            <v-col cols="12" class="d-flex justify-end align-end pa-0">
              <v-btn v-for="type in types"
                     :class="{'black-btn' : activeType === type.id}"
                     style="font-size: 12px;"
                     tile outlined depressed :key="type.id" @click="show_gamme(type.id)">

                {{ type.name | checkGamme }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row class="white py-4 pb-12">
        <v-col>
          <v-data-table
            :headers="gamme_headers"
            :items="gamme_products"
            :disable-sort="true"
            :hide-default-footer="true"
            :loading="loading"
            loading-text="Loading... Please wait"
            class="elevation-1"
          >
            <template v-slot:item="row">
              <tr>
                <td>{{ row.item.file.name }}</td>
                <td>
                  <v-btn
                    icon
                    small
                    color="black"
                  >
                    <v-icon @click="produit_detail(row.item)" dark>mdi-eye</v-icon>
                  </v-btn>
                </td>
                <td>
                  <v-btn
                    @click="download_file_modal(row.item.file)"
                    icon
                    small
                    color="black"
                  >
                    <v-icon dark>mdi-download</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>

          <template>
            <div class="text-right">
              <v-pagination
                color="grey"
                v-model="current_page"
                :length="last_page"
                @input="go_to_page"
                prev-icon="mdi-menu-left"
                next-icon="mdi-menu-right"
              ></v-pagination>
            </div>
          </template>
        </v-col>
      </v-row>
    </v-container>

    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Navbar from "@/components/Navbar.vue";
import ApiService from "@/services/api.service";
import axios from "axios";
export default {
  mounted() {
    this.getAllTypes();
  },

  data() {
    return {
      gammes_cuisine_show: true,
      gammes_interieurs_show: false,
      gammes_electromenagers_show: false,

      current_page: 1,
      last_page: 1,
      loading: false,

      types: [],
      activeType: null,
      url: null,

      gammes_cuisine_headers: [
        { text: "Cuisine", value: "cuisine", class: "black--text" },
        { text: "Afficher", class: "black--text" },
        { text: "Télécharger", class: "black--text" },
      ],
      gammes_cuisine_data: [],

      gammes_interieurs_headers: [
        { text: "Interieurs", value: "interieur", class: "black--text" },
        { text: "Afficher", class: "black--text" },
        { text: "Télécharger", class: "black--text" },
      ],
      gammes_interieurs_data: [],

      gammes_electromenagers_headers: [
        {
          text: "Materiels fournisseur",
          value: "materiels_fournisseur",
          class: "black--text",
        },
        { text: "Afficher", class: "black--text" },
        { text: "Télécharger", class: "black--text" },
      ],
      gammes_electromenagers_data: [],

      gamme_headers: [
        { text: 'Produit', value: 'name', class:"black--text" },
        { text: 'Afficher', class:"black--text"},
        { text: 'Télécharger', class:"black--text" },
      ],
      gamme_products: [],
    };
  },

  filters: {
    checkGamme(gamme) {
      if (gamme.includes("Gammes")) {
        return gamme;
      } else {
        return 'Gammes '+gamme;
      }
    }
  },

  components: {
    Footer,
    Navbar,
  },
  methods: {
    getAllTypes() {
      ApiService.get(`${this.$app_url}/api/v1/agency/product/types`)
          .then((res) => {
            this.types = res.data.data;
            this.activeType = res.data.data[0].id;
            this.url = `${this.$app_url}/api/v1/agency/product/type/${res.data.data[0].id}`;
            this.fetch_gamme(this.url);
          })
          .catch(err => console.log(err))
    },

    show_gamme(id) {
      this.gamme_products = []
      this.activeType = id
      this.url = `${this.$app_url}/api/v1/agency/product/type/${id}`;
      this.fetch_gamme(this.url)
    },

    fetch_gamme(url) {
      this.loading = true
      ApiService.get(url)
          .then((res) => {
            this.current_page = res.data.current_page
            this.last_page = res.data.last_page
            this.gamme_products = res.data.data
            this.loading = false
          })
          .catch(err => console.log(err))
    },

    go_to_page(page) {
      this.url = `${this.$app_url}/api/v1/agency/product/type/${this.activeType}?page=${page}`;
      this.fetch_gamme(this.url)
    },

    download_file_modal(file) {
      this.$confirm(
          {
            message: `Voulez-vous télécharger le fichier ?`,
            button: { no: 'Non', yes: 'Oui' },
            callback: confirm => {
              if (confirm) {
                axios({
                  url: `${this.$app_url}/api/v1/user/file/${file.id}`,
                  method: "GET",
                  responseType: "blob",
                }).then((response) => {
                  const ext = response.data.type.split("/")[1];
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", `${file.name}.${ext}`);
                  document.body.appendChild(link);
                  link.click();
                });
              }
            }
          }
      )

    },

    produit_detail(produit) {
      this.$router.push({
        name: 'ProduitDetail',
        params: {
          produit: produit,
        }
      })
    },

  },
};
</script>
